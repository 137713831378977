import React, {SetStateAction} from 'react';
import {Button, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";

interface IProps {
    show: boolean;
    handleCreate: () => void;
    handleClose: () => void;
    title: string;
    name: string;
    setName: React.Dispatch<SetStateAction<string>>
}

const CreateSpecificationModal = ({show, title, handleClose, handleCreate, setName, name}: IProps) => {
    const {t} = useTranslation();
    return (
        <Modal show={show} onHide={handleClose} size="sm" centered>
            <Modal.Body className="d-flex align-items-center flex-column">
                <h5>{title}</h5>
                <input type="text"
                       value={name}
                       onChange={(e) => {
                           setName(e.target.value)
                       }}
                       placeholder={'Specification name'}
                       className={'form-control mt-3'}
                />
            </Modal.Body>
            <Modal.Footer className="d-flex align-items-center justify-content-center">
                <Button variant="light" size="sm" onClick={handleClose}>
                    {t(`modals.close`)}
                </Button>
                <Button
                    variant="primary"
                    size="sm"
                    onClick={() => {
                        handleClose();
                        handleCreate();
                    }}
                >
                    {t(`global.create`)}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateSpecificationModal;