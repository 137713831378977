import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Form} from "react-bootstrap";
import {ErrorToast, SuccessToast} from "../../../shared/toasters/toasters";
import {AdminUserRegistrationService} from "../service";
import CreateForm, {IUserRegistration} from "./CreateForm";
import {AdminCarService} from "../../car/service";

const CreateUserRegistration = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [state, setState] = useState<IUserRegistration | null>(null);

    const submitHandler = () => {
        AdminUserRegistrationService.create(
            {
                ...state,
                cars: (state as IUserRegistration)?.cars?.map((car: { value: number }) => car?.value) as number[]
            })
            .then((response) => {
                SuccessToast(t(`user_registration.successfully_created`));
                navigate(-1);
                setState(null);
            })
            .catch((error) => ErrorToast(error));
    };

    useEffect(() => {
        setState({
            lateMorning: false,
            active: false,
            lunch: false,
            newsletter: false,
            forFree: false,
            reader: false
        } as IUserRegistration)
    }, [])
    return (
        <div className="col-12">
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    submitHandler();
                }}
            >
                <CreateForm state={state} setState={setState}/>
            </Form>
        </div>
    );
};

export default CreateUserRegistration;