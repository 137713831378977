import React, {useEffect, useState} from "react";
import {ErrorToast, SuccessToast} from "../../shared/toasters/toasters";
import {AdminMediadatenService} from "./service";
import {useTranslation} from "react-i18next";
import {Pagination} from "../../shared/paginations/Paginations";
import {useNavigate} from "react-router-dom";
import {List} from "./components/List";
import DraggableBootstrapTable from "../../shared/components/draggableList/Draggable";
import {IMediadaten} from "./components/UpdateMediadaten";

export const AdminMediadaten = () => {
    const {t} = useTranslation()
    const navigate = useNavigate();
    const [list, setList] = useState<IMediadaten[]>([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [pagination, setPagination] = useState({
        page: 1,
        perPage: 10,
    });
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [selectedPrompt, setSelectedPrompt] = useState<any>();

    const handlePages = (updatePage: number) => {
        setPagination({...pagination, page: updatePage});
        setPage(updatePage);
    };

    const deleteHandler = () => {
        selectedPrompt?.id &&
        AdminMediadatenService.delete(selectedPrompt?.id)
            .then((response) => {
                if (response?.status === "success") {
                    SuccessToast(t(`mediadaten.successfully_deleted`));
                    setPagination(prev=>({...prev, updatedAt: new Date().getTime()}))
                }
            })
            .catch((error) => ErrorToast(error));
    };

    const editHandler = (id: number) => {
        navigate(`/admin/media-data/update/${id}`);
    };

    const updatePosition = (newList: any) => {
        const newListUpdated = newList?.map((item: any) => item.id);
        AdminMediadatenService.updatePosition({ids: newListUpdated});
    };

    useEffect(() => {
        AdminMediadatenService.getAll(pagination)
            .then((response) => {
                const {data} = response;
                setList(data.items)
                setPage(data?.page);
                setTotalPages(Math.ceil(data?.totalItems / data?.perPage));
            })
            .catch((error) => ErrorToast(error));
    }, [pagination])
    return (
        <>
            <div className="col-12">
                <div className="card">
                    <div className="card-body d-flex align-items-center ">
                        <div className="search-box">
                            <input
                                type="text"
                                className="form-control"
                                id="searchMemberList"
                                onChange={(e: any) =>
                                    setPagination((prev: any) => ({
                                        ...prev,
                                        page: 1,
                                        term: e.target.value,
                                    }))
                                }
                                placeholder={t("global.search")}
                            />
                            <i className="ri-search-line search-icon"></i>
                        </div>
                        <button
                            className="btn btn-primary ms-auto"
                            onClick={() => navigate("/admin/media-data/create")}
                        >
                            {t("global.add")}
                        </button>
                    </div>
                </div>
            </div>
            <div className="col-12">
                {page > 1 ? (
                    <List list={list}
                          setList={setList}
                          editHandler={editHandler}
                          deleteHandler={deleteHandler}
                          selectedPrompt={selectedPrompt}
                          setSelectedPrompt={setSelectedPrompt}
                    />
                ) : (
                    <DraggableBootstrapTable
                        preview={true}
                        list={list}
                        setList={setList}
                        headers={["id", "title", "isActive"]}
                        title="mediadaten"
                        onEdit={editHandler}
                        selectedPrompt={selectedPrompt}
                        setSelectedPrompt={setSelectedPrompt}
                        handleShow={handleShow}
                        show={show}
                        setShow={setShow}
                        deleteHandler={deleteHandler}
                        updatePosition={updatePosition}
                    />
                )}
            </div>
            {page && (
                <div className="d-flex justify-content-end">
                    {totalPages <= 1 ? (
                        ""
                    ) : (
                        <Pagination
                            page={page}
                            totalPages={totalPages}
                            handlePagination={handlePages}
                        />
                    )}
                </div>
            )}
        </>
    );
};
