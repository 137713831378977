import {requests} from "../../helpers/api.services";
import {CAR_SPECIFICATION_URL} from "../../helpers/api.routes";
import {ICarSpecification} from "./index";
import {IPagination} from "../model/interface";

export const AdminCarSpecificationService = {
    getAll: (params: IPagination, active?: boolean) => requests.get(CAR_SPECIFICATION_URL, {...params, active}),
    getSingle: (id: number) => requests.get(`${CAR_SPECIFICATION_URL}/${id}`),
    create: (payload: ICarSpecification) => requests.post(CAR_SPECIFICATION_URL, payload),
    update: (payload: ICarSpecification, id: number) => requests.put(`${CAR_SPECIFICATION_URL}/${id}`, payload),
    delete: (id: number) => requests.delete(`${CAR_SPECIFICATION_URL}/${id}`),
    updatePosition: (ids: number[]) => requests.patch(`${CAR_SPECIFICATION_URL}`, {ids}),
}