import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ErrorToast, SuccessToast} from "../../shared/toasters/toasters";
import {AdminCarService} from "./service";
import List from "./components/List";
import DraggableBootstrapTable from "../../shared/components/draggableList/Draggable";
import {Pagination} from "../../shared/paginations/Paginations";

const AdminCar = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const [selectedPrompt, setSelectedPrompt] = useState<any>();
    const [totalPages, setTotalPages] = useState(0);
    const [pagination, setPagination] = useState({
        page: 1,
        perPage: 10,
        term: "",
    });
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handlePages = (updatePage: number) => {
        setPagination({...pagination, page: updatePage});
        setPage(updatePage);
    };
    const editHandler = (id: number): void => {
        navigate(`/admin/car/update/${id}`);
    };

    const updatePosition = (newList: any) => {
        const newListUpdated = newList?.map((item: any) => item.id);
        AdminCarService.updatePosition({ ids: newListUpdated });
    };
    const deleteHandler = () => {
        selectedPrompt?.id &&
        AdminCarService.delete(selectedPrompt?.id)
            .then((response) => {
                if (response?.status === "success") {
                    SuccessToast(t(`cars.successfully_deleted`));
                    setPagination((prev)=> ({...prev, updatedAt: new Date().getTime()}));
                }
            })
            .catch((error) => ErrorToast(error));
    };

    useEffect(() => {
        AdminCarService.getAll(pagination)
            .then((response) => {
                const {data} = response;
                setList(data?.items);
                setTotalPages(Math.ceil(data?.totalItems / data?.perPage));
            })
            .catch((error) => ErrorToast(error));
    }, [pagination]);
    return (
        <>
            <div className="col-lg-12">
                <div className="card">
                    <div className="card-body">
                        <div className="row g-2">
                            <div className="col-sm-4">
                                <div className="search-box">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="searchMemberList"
                                        placeholder="Search for name or designation..."
                                        onChange={(e: any) =>
                                            setPagination((prev: any) => ({
                                                ...prev,
                                                page: 1,
                                                term: e.target.value,
                                            }))
                                        }
                                    />
                                    <i className="ri-search-line search-icon"></i>
                                </div>
                            </div>
                            <div className="col-sm-auto ms-auto">
                                <div className="list-grid-nav hstack gap-1">
                                    <button
                                        className="btn btn-info"
                                        onClick={() => navigate("/admin/car/create")}
                                    >
                                        {t("global.add")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {page > 1 || pagination?.term ? (
                <>
                    <div className="col-12">
                        <List
                            list={list}
                            editHandler={editHandler}
                            deleteHandler={deleteHandler}
                            selectedPrompt={selectedPrompt}
                            setSelectedPrompt={setSelectedPrompt}
                            show={show}
                            setShow={setShow}
                        />
                    </div>{" "}
                </>
            ) : (
                    <div className="col-12">
                        <DraggableBootstrapTable
                            preview={true}
                            list={list!}
                            setList={setList}
                            headers={["id", "title", "isActive"]}
                            title="cars"
                            onEdit={editHandler}
                            selectedPrompt={selectedPrompt}
                            setSelectedPrompt={setSelectedPrompt}
                            handleShow={handleShow}
                            show={show}
                            setShow={setShow}
                            deleteHandler={deleteHandler}
                            updatePosition={updatePosition}
                        />
                    </div>
            )}
            {page && (
                <div className="d-flex justify-content-end ">
                    {totalPages <= 1 ? (
                        ""
                    ) : (
                        <Pagination
                            page={page}
                            totalPages={totalPages}
                            handlePagination={handlePages}
                        />
                    )}
                </div>
            )}
        </>
    );
};

export default AdminCar;