import React, {useEffect, useState} from "react";
import {ErrorToast} from "../../../../shared/toasters/toasters";
import {List} from "./components/List";
import {AdminNewsService} from "../../../news/service";
import {AdminNewsLetterService} from "./service";

export const AdminNewsLetterCreate = ({
  state,
  setState,
  setSelectedNews,
}: any) => {
  const [triggerUpdate, setTriggerUpdate] = useState(false);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
    featured: 1
  });
  const [list, setList] = useState(undefined);

  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };

  useEffect(() => {
    AdminNewsLetterService.getAllNewsletter(pagination)
      .then((response) => {
        const { data } = response;
        setList(data?.items);
        setTotalPages(Math.ceil(data?.totalItems / data?.perPage));
      })
      .catch((error) => ErrorToast(error));
  }, [triggerUpdate, pagination]);

  return (
    <>
      <div className="col-12">
        <List
          page={page}
          handlePages={handlePages}
          totalPages={totalPages}
          list={list}
          setTriggerUpdate={setTriggerUpdate}
          setState={setState}
          state={state}
          setSelectedNews={setSelectedNews}
          setPagination={setPagination}
        />
      </div>
    </>
  );
};
