import React, {useEffect, useState} from 'react';
import {Form} from "react-bootstrap";
import CreateForm from "./CreateForm";
import {useTranslation} from "react-i18next";
import {AdminCarSpecificationService} from "../service";
import {ErrorToast, SuccessToast} from "../../../shared/toasters/toasters";
import {useNavigate, useParams} from "react-router-dom";
import {ICarSpecification} from "../index";

const UpdateSpecification = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [state, setState] = useState<ICarSpecification | null>(null);
    const {id} = useParams();

    const submitHandler = () => {
        !!state?.id && AdminCarSpecificationService.update(state, state?.id)
            .then(() => {
                SuccessToast(t(`car_specifications.successfully_updated`));
                navigate(-1);
                setState(null);
            })
            .catch((error) => ErrorToast(error));
    };

    useEffect(()=>{
        if(id){
            AdminCarSpecificationService.getSingle(+id)
                .then(response=>{
                    setState(response?.data)
                }).catch(err=> ErrorToast(err))
        }
    },[id])
    return (
        <div className="col-12">
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    submitHandler();
                }}
            >
                <CreateForm state={state} setState={setState}/>
            </Form>
        </div>
    );
};

export default UpdateSpecification;