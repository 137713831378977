import React, {useState} from 'react';
import {Form} from "react-bootstrap";
import {CreateForm} from "./CreateForm";
import {AdminMediadatenService} from "../service";
import {ErrorToast, SuccessToast} from "../../../shared/toasters/toasters";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

const CreateMediadaten = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [data, setData] = useState({isActive: false});
    const [document, setDocument] = useState();
    const submitHandler = () => {
        const formData = new FormData();
        formData.append("body", JSON.stringify(data));
        document && formData.append("document", document);

        AdminMediadatenService.create(formData)
            .then(() => {
                SuccessToast(t(`settings.successfully_saved`));
                navigate(-1)
            })
            .catch((error) => ErrorToast(error));
    };
    return (
        <div className="col-12">
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    submitHandler();
                }}
            >
                <CreateForm
                    state={data}
                    setState={setData}
                    setDocument={setDocument}
                />
            </Form>
        </div>
    );
};

export default CreateMediadaten;