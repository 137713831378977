import {useDrag, useDrop} from "react-dnd";

interface IProps{
    index: number;
    list: any[];
    updatePosition: (newArr: any) => void;
    updateList: (updatedList: any) => void;
}
export const useDnD = ({index, list, updatePosition, updateList}:IProps) =>{
    const [, drop] = useDrop({
        accept: "ROW",
        drop: (draggedItem: { index: number }) => {
            if (draggedItem.index !== index) {
                const newDraggedItem = {...draggedItem};
                moveRow(newDraggedItem.index, index);
                newDraggedItem.index = index;
            }
        },
    });

    const [, ref, preview] = useDrag({
        type: "ROW",
        item: {index},
    });

    const moveRow = (fromIndex: number, toIndex: number) => {
        const updatedRows = [...list];
        const [movedRow] = updatedRows.splice(fromIndex, 1);
        updatedRows.splice(toIndex, 0, movedRow);
        updateList(updatedRows);
        updatePosition && updatePosition(updatedRows);
    };
    return {
        ref,
        preview,
        drop
    }
}