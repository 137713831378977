// DraggableBootstrapTable.tsx
import React, { useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Form, Table } from "react-bootstrap";
import { ConfirmDelete } from "../../../shared/components/ConfirmDelete";
import { Loader } from "../../../shared/components/Loader";
import { EmptyState } from "../../../shared/components/EmptyState";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

interface DataRow {
  id?: number;
  title?: string;
  age?: number;
  website?: string;
  name?: string;

  position?: string;
  url?: string;
}

interface DraggableRowProps {
  previewRow?: boolean;
  row: any;
  title?: string;
  index: number;
  haveMenuActions?: boolean;
  headers: string[];
  moveRow: (fromIndex: number, toIndex: number) => void;
  onEdit: any;
  list: any;
  handleShow: any;
  setSelectedPrompt: any;
  updatePosition: any;
  haveActions: boolean;
  titleClickHandler?: any;
  isDraggable: boolean;
  removeDeleteBtn: boolean;
  toggleIsActive?: (id: number) => void;
}

const DraggableRow: React.FC<DraggableRowProps> = ({
  previewRow,
  row,
  haveMenuActions,
  index,
  title,
  moveRow,
  headers,
  handleShow,
  onEdit,
  setSelectedPrompt,
  haveActions,
  titleClickHandler,
  isDraggable = true,
  removeDeleteBtn,
  toggleIsActive,
}) => {
  const domain = useSelector((state: any) => state.company.clientDomain);
  const { t } = useTranslation();
  const isFeatured = document.location.pathname.includes("featured");
  const choice = document.location.pathname.includes("editor-choice");
  const category = document.location.pathname.includes("admin/category");
  const [, drop] = useDrop({
    accept: "ROW",
    drop: (draggedItem: { index: number }) => {
      if (draggedItem.index !== index) {
        const newDraggedItem = { ...draggedItem };
        moveRow(newDraggedItem.index, index);
        newDraggedItem.index = index;
      }
    },
  });

  const [, ref, preview] = useDrag({
    type: "ROW",
    item: { index },
  });
  const getCorrectTd = (header: string) => {
    switch (header) {
      case "name" || "title":
        return (
          <td
            className={`title-ellipsis ${titleClickHandler && "cursor-pointer"}`}
            onClick={() => titleClickHandler && titleClickHandler(row.id)}
            key={header}
          >
            {row[header]}
          </td>
        );
      case "color":
        return (
          <td
            style={
              title === "alternativeDriveCat"
                ? { color: row[header] }
                : { color: row["category"][header] }
            }
          >
            <div
              style={{
                height: "20px",
                width: "20px",
                backgroundColor:
                  title === "alternativeDriveCat"
                    ? row[header]
                    : row["category"][header],
              }}
            ></div>
            {/* {row["category"][header]} */}
          </td>
        );
      case "active":
      case "isActive":
        return (
          <td className={`text-${row[header] ? "success" : "danger"}`}>
            <div className={"d-flex align-items-center"}>
              {row[header] ? (
                <span>
                  <i className="ri-checkbox-circle-line fs-17 align-middle" />{" "}
                  {t(`global.active`)}
                </span>
              ) : (
                <span>
                  <i className="ri-close-circle-line fs-17 align-middle" />
                  {t(`global.inactive`)}
                </span>
              )}
              {toggleIsActive && (
                <Form.Check
                  type="switch"
                  className={"cursor-pointer ms-3"}
                  checked={row?.isActive}
                  onClick={() => toggleIsActive(row?.id)}
                />
              )}
            </div>
          </td>
        );
      case "showOnShelf":
        return (
          <td className={`text-${row[header] ? "success" : "danger"}`}>
            <div className={"d-flex align-items-center"}>
              {row[header] ? (
                <span>
                  <i className="ri-checkbox-circle-line fs-17 align-middle" />{" "}
                  {t(`global.yes`)}
                </span>
              ) : (
                <span>
                  <i className="ri-close-circle-line fs-17 align-middle" />
                  {t(`global.no`)}
                </span>
              )}
            </div>
          </td>
        );
      case "language":
        return title === "alternativeDrive" ? (
          <td key={header}>
            {t(`languages.${row["category"][header]?.toLowerCase()}`)}
          </td>
        ) : (
          <td key={header}>{t(`languages.${row[header]?.toLowerCase()}`)}</td>
        );
      case "event":
        return title === "event_sum_cat" ? (
          <td key={row[header]?.id}>{row[header]?.title}</td>
        ) : (
          <td key={header}>{row[header]}</td>
        );
      case "category":
        return <td key={row[header]?.id}>{row[header]?.name}</td>;
      case "categories":
        return <td key={row[header]?.id}>{row[header]?.map((category:({name: string}))=> category?.name)?.join(", ")}</td>;
      case "title":
        return (
          <td key={row[header]} className={"title-ellipsis"}>
            {row[header]}
          </td>
        );
      default:
        return <td key={header}>{row[header]}</td>;
    }
  };
  return (
    <tr ref={(node) => preview(drop(node))}>
      <td>
        {isDraggable && (
          <div className="handle cursor-move" ref={ref}>
            <i className="ri-drag-move-line"></i>
          </div>
        )}
      </td>
      {headers.map((header) => {
        return getCorrectTd(header);
        // <>
        //   {header === "name" || header === "title" ? (
        //     <td
        //       className={`${titleClickHandler && "cursor-pointer"}`}
        //       onClick={() => titleClickHandler && titleClickHandler(row.id)}
        //       key={header}
        //     >
        //       {row[header]}
        //     </td>
        //   ) : header === "isActive" ? (
        //     <td className={`text-${row[header] ? "success" : "danger"}`}>
        //       {row[header] ? (
        //         <span>
        //           <i className="ri-checkbox-circle-line fs-17 align-middle" />{" "}
        //           {t(`global.active`)}
        //         </span>
        //       ) : (
        //         <span>
        //           <i className="ri-close-circle-line fs-17 align-middle" />
        //           {t(`global.inactive`)}
        //         </span>
        //       )}
        //     </td>
        //   ) : (
        //     <td key={header}>{row[header]}</td>
        //   )}
        // </>
      })}
      {haveActions && (
        <td className="col-2">
          <div className="d-flex">
            {previewRow ||
              (!choice && (
                <a
                  href={domain + "news/" + row?.slug}
                  target="_blank"
                  className="btn btn-sm btn-info d-flex align-items-center me-2"
                >
                  <i className="ri-edit-2-fill me-1" />
                  {t("global.preview")}
                </a>
              ))}
            {haveMenuActions && (
              //  href={`/admin/menu/submenu/${row?.id}`}
              <a
                href={
                  title === "productCategory"
                    ? `/admin/product-category/subcategory/${row?.id}`
                    : `/admin/menu/submenu/${row?.id}`
                }
                className="btn btn-sm btn-info d-flex align-items-center me-2"
              >
                {title === "productCategory"
                  ? t("product.subcategory")
                  : t("submenu.submenu")}
              </a>
            )}
            <button
              className={`btn btn-sm btn-warning d-flex ${
                removeDeleteBtn ? "mx-auto" : "me-2"
              }`}
              onClick={() => onEdit(row?.id)}
            >
              <i className="ri-edit-2-fill me-1" />
              {t("global.edit")}
            </button>
            {!removeDeleteBtn && (
              <button
                className="btn btn-sm btn-danger d-flex "
                onClick={() => {
                  handleShow();
                  setSelectedPrompt(row);
                }}
              >
                <i className="ri-delete-bin-fill me-1" />
                {isFeatured
                  ? t("featuredArticle.remove_featured")
                  : choice
                    ? t("global.remove_from_list")
                    : t("global.delete")}
              </button>
            )}
          </div>
        </td>
      )}
    </tr>
  );
};

interface DraggableBootstrapTableProps {
  preview?: boolean;
  haveMenuActions?: boolean;
  setList: any;
  list: DataRow[];
  headers: string[];
  title: string;
  onEdit?: any;
  handleShow?: any;
  setSelectedPrompt?: any;
  selectedPrompt?: any;
  show?: boolean;
  setShow?: any;
  deleteHandler?: any;
  updatePosition?: any;
  isDraggable?: boolean;
  haveActions?: boolean;
  titleClickHandler?: any;
  removeDeleteBtn?: boolean;
  toggleIsActive?: (id: number) => void;
  ctaHeaderBtnClick?: () => void;
  ctaHeaderBtnTitle?: string;
}

const DraggableBootstrapTable: React.FC<DraggableBootstrapTableProps> = ({
  preview = false,
  removeDeleteBtn = false,
  haveMenuActions = false,
  setList,
  list,
  headers,
  title,
  onEdit,
  handleShow,
  setSelectedPrompt,
  selectedPrompt,
  show,
  setShow,
  deleteHandler,
  titleClickHandler,
  updatePosition,
  isDraggable = true,
  haveActions = true,
  toggleIsActive,
  ctaHeaderBtnClick,
  ctaHeaderBtnTitle,
}) => {
  const [tableHeaders, setTableHeaders] = useState(headers);
  const { t } = useTranslation();
  const moveRow = (fromIndex: number, toIndex: number) => {
    const updatedRows = [...list];
    const [movedRow] = updatedRows.splice(fromIndex, 1);
    updatedRows.splice(toIndex, 0, movedRow);
    setList(updatedRows);
    updatePosition && updatePosition(updatedRows);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="card">
        <div className="card-header pb-0">
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="card-title mg-b-0">
              {t(
                `${title}.listOf${
                  title[0].toUpperCase() + title.slice(1) + "s"
                }`
              )}
            </h4>
            {ctaHeaderBtnClick && (
              <button
                className={"btn btn-primary btn-sm mb-2"}
                onClick={ctaHeaderBtnClick}
              >
                {ctaHeaderBtnTitle}
              </button>
            )}
          </div>
        </div>
        <div className="card-body">
          {list == undefined ? (
            <Loader />
          ) : list.length === 0 ? (
            <EmptyState />
          ) : (
            <div className="table-responsive">
              <Table className="align-middle table-nowrap mb-0">
                <thead className="table-light">
                  <tr>
                    <th scope="col"></th>
                    {tableHeaders.map((header) => (
                      <th scope="col" key={header}>
                        {t(`table_headers.${header}`)}
                      </th>
                    ))}
                    {haveActions && (
                      <th scope="col" className="text-center">
                        {removeDeleteBtn
                          ? t("global.action")
                          : t("global.actions")}
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {list.map((row, index) => (
                    <DraggableRow
                      isDraggable={isDraggable}
                      haveMenuActions={haveMenuActions}
                      previewRow={preview}
                      key={row.id}
                      list={list}
                      title={title}
                      row={row}
                      index={index}
                      moveRow={moveRow}
                      headers={tableHeaders}
                      onEdit={onEdit}
                      handleShow={handleShow}
                      updatePosition={updatePosition}
                      setSelectedPrompt={setSelectedPrompt}
                      haveActions={haveActions}
                      titleClickHandler={titleClickHandler}
                      removeDeleteBtn={removeDeleteBtn}
                      toggleIsActive={toggleIsActive}
                    />
                  ))}
                </tbody>
              </Table>
            </div>
          )}

          <ConfirmDelete
            show={show}
            setShow={setShow}
            itemName={
              selectedPrompt?.name ??
              selectedPrompt?.title ??
              selectedPrompt?.email
            }
            deleteHandler={deleteHandler}
            product={selectedPrompt}
            selectedProduct={selectedPrompt}
          />
        </div>
      </div>
    </DndProvider>
  );
};

export default DraggableBootstrapTable;
