import React, {useEffect, useState} from 'react';
import {Card, Col, Form, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {ErrorToast, SuccessToast} from "../../shared/toasters/toasters";
import {InitEditorMoreFields} from "../model/components/CreateForm";
import {AdminUserRegistrationSettingsService} from "./service";
import {useNavigate} from "react-router-dom";

interface IUserRegistrationSettingsProps {
    title: string,
    subTitle?: string,
    text?: string;
    disableFields?: boolean,
    hide?: boolean
}
const AdminUserRegistrationSettings = () => {
    const [state, setState] = useState<IUserRegistrationSettingsProps | null>(null)
    const {t} = useTranslation();
    const navigate = useNavigate();

    const changeHandler = (event: any) => {
        const {name, value} = event.target;
        setState((prev) => ({...prev, [name]: value}) as IUserRegistrationSettingsProps);
    };
    const handleText = (text: string) => {
        setState((prev) => ({...prev, text}) as IUserRegistrationSettingsProps);
    };

    const switchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState((prev) => ({...prev, [e.target.name]: e.target.checked}) as IUserRegistrationSettingsProps);
    };
    const submitHandler = () => {
        AdminUserRegistrationSettingsService.create(state)
            .then((response) => {
                SuccessToast(t(`user_registration.successfully_updated_form`));
                setState(response.data)
            }).catch((error) => ErrorToast(error))
    }

    useEffect(() => {
        AdminUserRegistrationSettingsService.get()
            .then((response) => {
                const {data} = response;
                setState(data);
                InitEditorMoreFields(data?.text ?? "", handleText, ".text");
            })
            .catch((error) => ErrorToast(error));
    }, []);

    return (
        <Form onSubmit={(e) => {
            e.preventDefault();
            submitHandler();
        }}>
            <Card>
                <Card.Body>
                    <Row>
                        <Col md={4}>
                            <label htmlFor="title" className="required-field">
                                {t("global.title")}
                            </label>
                            <input
                                type="text"
                                name="title"
                                required
                                className="form-control"
                                onChange={changeHandler}
                                value={state?.title}
                            />
                        </Col>
                        <Col md={4}>
                            <label htmlFor="subTitle" className="required-field">
                                {t("global.subtitle")}
                            </label>
                            <input
                                type="text"
                                name="subTitle"
                                className="form-control"
                                onChange={changeHandler}
                                value={state?.subTitle}
                            />
                        </Col>
                        <Col md={2}>
                            <div className="form-check form-switch p-0">
                                <label
                                    className="d-block text-start"
                                    htmlFor="disableFieldsSwitch"
                                >
                                    {t("global.disable_fields")}
                                </label>
                                <div className="row">
                                    <input
                                        className="form-check-input switch-custom ms-4 mt-2"
                                        type="checkbox"
                                        name={"disableFields"}
                                        checked={state?.disableFields}
                                        onChange={switchHandler}
                                        role="switch"
                                        id="disableFieldsSwitch"
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col md={2}>
                            <div className="form-check form-switch p-0">
                                <label
                                    className="d-block text-start"
                                    htmlFor="hideSwitch"
                                >
                                    {t("global.hide")}
                                </label>
                                <div className="row">
                                    <input
                                        className="form-check-input switch-custom ms-4 mt-2"
                                        type="checkbox"
                                        name={"hide"}
                                        checked={state?.hide}
                                        onChange={switchHandler}
                                        role="switch"
                                        id="hideSwitch"
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col md={12}>
                            <label htmlFor="title" className="required-field mt-3">
                                {t("global.text")}
                            </label>
                            <textarea className="editor text"></textarea>
                        </Col>
                    </Row>

                    <div className="col-12 my-2 d-flex justify-content-end text-align-center">
                            <button
                                className="btn btn-info"
                                type={'button'}
                                onClick={() => {
                                    setState(null);
                                    navigate(-1)
                                }}
                            >
                                {t("global.cancel")}
                            </button>
                            <button className="btn btn-primary ms-3" type="submit">
                                {t("global.save")}
                            </button>
                    </div>
                </Card.Body>
            </Card>
        </Form>
    );
};

export default AdminUserRegistrationSettings;