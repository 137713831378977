import React, {useEffect, useState} from 'react';
import {Form} from "react-bootstrap";
import {CreateForm} from "./CreateForm";
import {useTranslation} from "react-i18next";
import {AdminMediadatenService} from "../service";
import {ErrorToast, SuccessToast} from "../../../shared/toasters/toasters";
import {useNavigate, useParams} from "react-router-dom";

export interface IMediadaten {
    id: number,
    title: string,
    isActive: boolean
}

const UpdateMediadaten = () => {
    const {t} = useTranslation();
    const {id} = useParams();
    const [data, setData] = useState<IMediadaten>();
    const [document, setDocument] = useState();
    const navigate = useNavigate();
    const submitHandler = () => {
        const formData = new FormData();
        formData.append("body", JSON.stringify(data));
        document && formData.append("document", document);

        !!id && AdminMediadatenService.update(Number(id), formData)
            .then(() => {
                SuccessToast(t(`settings.successfully_saved`));
                navigate(-1);
            })
            .catch((error) => ErrorToast(error));
    };

    useEffect(() => {
        !!id && AdminMediadatenService.getSingle(Number(id))
            .then((response) => {
                setData(response?.data)
            }).catch(err => ErrorToast(err))
    }, [id])
    return (
        <div className="col-12">
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    submitHandler();
                }}
            >
                <CreateForm
                    state={data}
                    setState={setData}
                    setDocument={setDocument}
                />
            </Form>
        </div>
    );
};

export default UpdateMediadaten;