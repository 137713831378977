import React from 'react';
import {Col, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useDnD} from "../../../shared/hooks/useDnD";
import {ISpecificationData} from "../index";

interface IProps {
    index: number;
    schema: ISpecificationData;
    changeSpecificationHandler: (e: React.ChangeEvent<HTMLInputElement>, schema: ISpecificationData) => void;
    handleDeleteSpecification: (schema: ISpecificationData) => void;
    updatePosition: (newArr: any) => void;
    list: ISpecificationData[];
    updateList: (updatedList: any) => void;
}

const DraggableSchema = ({
                             schema,
                             index,
                             changeSpecificationHandler,
                             handleDeleteSpecification,
                             updatePosition,
                             list,
                             updateList
                         }: IProps) => {
    const {t} = useTranslation();
    const {ref, preview, drop} = useDnD({index, list, updatePosition, updateList});
    return (
        <Row ref={(node: any) => preview(drop(node))}>
            <Col md={1} className={'mb-2 d-flex align-items-center justify-content-center'}>
                {schema?.id && <div className="handle cursor-move" ref={ref}>
                    <i className="ri-drag-move-line"></i>
                </div>}
            </Col>
            <Col md={11} className={'d-flex align-items-end mb-2'}>
                <input
                    type="text"
                    value={schema?.value}
                    name="value"
                    className="form-control w-100"
                    required
                    onChange={(e) => changeSpecificationHandler(e, schema)}
                />
                <button onClick={() => handleDeleteSpecification(schema)}
                        className="btn btn-danger btn-sm ms-1 mb-1"
                        type={'button'}>
                    {t('global.delete')}
                </button>
            </Col>
        </Row>
    );
};

export default DraggableSchema;