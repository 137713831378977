import React, {SetStateAction, useEffect, useState} from 'react';
import {Card, Col, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {AdminCarService} from "../../car/service";
import {ErrorToast} from "../../../shared/toasters/toasters";
import Select, {MultiValue} from "react-select";
import {ISelect} from "../../../shared/interface";

export interface IUserRegistration {
    id?: number;
    greeting: string;
    fleetSize: string;
    company: string;
    firstName: string;
    lastName: string;
    title: string;
    address: string;
    additionalAddress?: string;
    postCode: string;
    location: string;
    email: string;
    phone: string;
    mobilePhone: string;
    lateMorning: boolean;
    lunch: boolean;
    active: boolean;
    newsletter: boolean;
    forFree: boolean;
    reader: boolean;
    cars: ICar[];
}

interface IProps {
    state: IUserRegistration | null,
    setState: React.Dispatch<SetStateAction<IUserRegistration | null>>
}

export interface ICar {
    value: number;
    label: string;
}

const CreateForm = ({state, setState}: IProps) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [cars, setCars] = useState<ICar[]>([]);
    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setState((prev) => ({...prev, [name]: value}) as IUserRegistration);
    };
    const selectHandler = (e: any, name: string) => {
        setState((prev: any) => ({...prev, [name]: e}));
    };
    const switchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState((prev) => ({...prev, [e.target.name]: e.target.checked}) as IUserRegistration);
    };

    useEffect(() => {
        AdminCarService.getAll({ page: 1, perPage: 0 }, true)
            .then((response) => {
                setCars(response?.data.items?.map((car: any) => ({value: car.id, label: car.title})));
            })
            .catch((error) => ErrorToast(error));
    }, []);

    return (
        <Card>
            <Card.Body>
                <Row>
                    <Col md={6}>
                        <label htmlFor="greeting" className="required-field">
                            {t("global.greeting")}
                        </label>
                        <input
                            type="text"
                            name="greeting"
                            className="form-control"
                            required
                            onChange={changeHandler}
                            value={state?.id && state?.greeting}
                        />
                    </Col>
                    <Col md={6}>
                        <label htmlFor="title" className="required-field">
                            {t("global.title")}
                        </label>
                        <input
                            type="text"
                            name="title"
                            className="form-control"
                            required
                            onChange={changeHandler}
                            value={state?.id && state?.title}
                        />
                    </Col>
                    <Col md={6} className={'mt-3'}>
                        <label htmlFor="company" className="required-field">
                            {t("global.company")}
                        </label>
                        <input
                            type="text"
                            name="company"
                            className="form-control"
                            required
                            onChange={changeHandler}
                            value={state?.id && state?.company}
                        />
                    </Col>
                    <Col md={6} className={'mt-3'}>
                        <label htmlFor="fleetSize" className="required-field">
                            {t("global.fleetSize")}
                        </label>
                        <input
                            type="text"
                            name="fleetSize"
                            className="form-control"
                            required
                            onChange={changeHandler}
                            value={state?.id && state?.fleetSize}
                        />
                    </Col>
                    <Col md={6} className={'mt-3'}>
                        <label htmlFor="firstName" className="required-field">
                            {t("global.firstName")}
                        </label>
                        <input
                            type="text"
                            name="firstName"
                            className="form-control"
                            required
                            onChange={changeHandler}
                            value={state?.id && state?.firstName}
                        />
                    </Col>
                    <Col md={6} className={'mt-3'}>
                        <label htmlFor="lastName" className="required-field">
                            {t("global.lastName")}
                        </label>
                        <input
                            type="text"
                            name="lastName"
                            className="form-control"
                            required
                            onChange={changeHandler}
                            value={state?.id && state?.lastName}
                        />
                    </Col>
                    <Col md={6} className={'mt-3'}>
                        <label htmlFor="address" className="required-field">
                            {t("global.address")}
                        </label>
                        <input
                            type="text"
                            name="address"
                            className="form-control"
                            required
                            onChange={changeHandler}
                            value={state?.id && state?.address}
                        />
                    </Col>
                    <Col md={6} className={'mt-3'}>
                        <label htmlFor="additionalAddress">
                            {t("global.additionalAddress")}
                        </label>
                        <input
                            type="text"
                            name="additionalAddress"
                            className="form-control"
                            onChange={changeHandler}
                            value={state?.id && state?.additionalAddress}
                        />
                    </Col>
                    <Col md={6} className={'mt-3'}>
                        <label htmlFor="postCode" className="required-field">
                            {t("global.postCode")}
                        </label>
                        <input
                            type="text"
                            name="postCode"
                            className="form-control"
                            required
                            onChange={changeHandler}
                            value={state?.id && state?.postCode}
                        />
                    </Col>
                    <Col md={6} className={'mt-3'}>
                        <label htmlFor="location" className="required-field">
                            {t("global.location")}
                        </label>
                        <input
                            type="text"
                            name="location"
                            className="form-control"
                            required
                            onChange={changeHandler}
                            value={state?.id && state?.location}
                        />
                    </Col>
                    <Col md={4} className={'mt-3'}>
                        <label htmlFor="email" className="required-field">
                            {t("global.email")}
                        </label>
                        <input
                            type="email"
                            name="email"
                            className="form-control"
                            required
                            onChange={changeHandler}
                            value={state?.id && state?.email}
                        />
                    </Col>
                    <Col md={4} className={'mt-3'}>
                        <label htmlFor="lastName" className="required-field">
                            {t("global.phone")}
                        </label>
                        <input
                            type="tel"
                            name="phone"
                            className="form-control"
                            required
                            onChange={changeHandler}
                            value={state?.id && state?.phone}
                        />
                    </Col>
                    <Col md={4} className={'mt-3'}>
                        <label htmlFor="mobilePhone" className="required-field">
                            {t("global.mobilePhone")}
                        </label>
                        <input
                            type="tel"
                            name="mobilePhone"
                            className="form-control"
                            onChange={changeHandler}
                            value={state?.id && state?.mobilePhone}
                        />
                    </Col>
                    <Col md={4} className={'mt-3'}>
                        <label htmlFor="cars" className="required-field">
                            {t("cars.cars")}
                        </label>
                        <Select
                            className="react-select-field"
                            onChange={(e) => selectHandler(e, "cars")}
                            options={cars as any}
                            value={state?.cars}
                            isMulti
                        />
                    </Col>
                    <Col md={2} className={'mt-3'}>
                        <label
                            className="d-block text-start"
                            htmlFor="lateMorning"
                        >
                            {t("global.lateMorning")}
                        </label>
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input switch-custom"
                                type="checkbox"
                                name="lateMorning"
                                checked={state?.lateMorning}
                                onChange={switchHandler}
                                role="switch"
                                id="lateMorning"
                            />
                        </div>
                    </Col>
                    <Col md={2} className={'mt-3'}>
                        <label
                            className="d-block text-start"
                            htmlFor="lunch"
                        >
                            {t("global.lunch")}
                        </label>
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input switch-custom"
                                type="checkbox"
                                name="lunch"
                                checked={state?.lunch}
                                onChange={switchHandler}
                                role="switch"
                                id="lunch"
                            />
                        </div>
                    </Col>
                    <Col md={2} className={'mt-3'}>
                        <label
                            className="d-block text-start"
                            htmlFor="active"
                        >
                            {t("global.active")}
                        </label>
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input switch-custom"
                                type="checkbox"
                                name="active"
                                checked={state?.active}
                                onChange={switchHandler}
                                role="switch"
                                id="active"
                            />
                        </div>
                    </Col>
                    <Col md={2} className={'mt-3'}>
                        <label
                            className="d-block text-start"
                            htmlFor="newsletter"
                        >
                            {t("newsletter.newsletter")}
                        </label>
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input switch-custom"
                                type="checkbox"
                                name="newsletter"
                                checked={state?.newsletter}
                                onChange={switchHandler}
                                role="switch"
                                id="newsletter"
                            />
                        </div>
                    </Col>
                    <Col md={2} className={'mt-3'}>
                        <label
                            className="d-block text-start"
                            htmlFor="forFree"
                        >
                            {t("user_registration.forFree")}
                        </label>
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input switch-custom"
                                type="checkbox"
                                name="forFree"
                                checked={state?.forFree}
                                onChange={switchHandler}
                                role="switch"
                                id="forFree"
                            />
                        </div>
                    </Col>
                    <Col md={2} className={'mt-3'}>
                        <label
                            className="d-block text-start"
                            htmlFor="reader"
                        >
                            {t("user_registration.reader")}
                        </label>
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input switch-custom"
                                type="checkbox"
                                name="reader"
                                checked={state?.reader}
                                onChange={switchHandler}
                                role="switch"
                                id="reader"
                            />
                        </div>
                    </Col>
                </Row>
                <div className="col-12 my-2 d-flex justify-content-end text-align-center">
                    <div>
                        <button
                            className="btn btn-info"
                            type={'button'}
                            onClick={() => {
                                navigate(-1);
                                setState(null);
                            }}
                        >
                            {t("global.cancel")}
                        </button>
                        <button className="btn btn-primary ms-3" type="submit">
                            {state?.id ? `  ${t("global.update")}` : `${t("global.create")}`}
                        </button>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
};

export default CreateForm;