import Lottie from "lottie-react";

import success from "../../../../shared/icons/success.json";
import {useTranslation} from "react-i18next";

const AdminNewsletterStep4 = ({ sentNumber }: any) => {
  const {t} = useTranslation();
  return (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center p-5">
        <Lottie animationData={success} loop={true} style={{ width: 200 }} />
        <h5>Success!</h5>
        <p className="text-center text-muted modal-title-break">
          {sentNumber}{t('newsletter.toBeSent')}
        </p>
        <p className="text-center text-muted modal-title-break">
          Newsletters are sent in the background, you can continue using the
          application.
        </p>
      </div>
    </>
  );
};

export default AdminNewsletterStep4;
