import React from 'react';
import {Col, Row} from "react-bootstrap";
import {IProgramSchema} from "../index";
import {useTranslation} from "react-i18next";
import {useDnD} from "../../../shared/hooks/useDnD";

interface IProps {
    index: number;
    schema: IProgramSchema;
    changeSpecificationHandler: (e: React.ChangeEvent<HTMLInputElement>, schema: IProgramSchema) => void;
    handleDeleteSpecification: (schema: IProgramSchema) => void;
    updatePosition: (newArr: any) => void;
    list: IProgramSchema[];
    updateList: (updatedList: any) => void;
    switchHandler : (e: React.ChangeEvent<HTMLInputElement>, schema: IProgramSchema) => void
}

const DraggableSchema = ({
                             schema,
                             index,
                             changeSpecificationHandler,
                             handleDeleteSpecification,
                             updatePosition,
                             list,
                             updateList,
                             switchHandler
                         }: IProps) => {
    const {t} = useTranslation();
    const {ref, preview, drop} = useDnD({index, list, updatePosition, updateList});
    return (
        <Row ref={(node: any) => preview(drop(node))}>
            <Col md={1} className={'mb-2 d-flex align-items-center justify-content-center'}>
                {schema?.id && <div className="handle cursor-move" ref={ref}>
                    <i className="ri-drag-move-line"></i>
                </div>}
            </Col>
            <Col md={5} className={'mb-2'}>
                <input
                    type="text"
                    value={schema?.key}
                    name="key"
                    className="form-control w-100"
                    required
                    onChange={(e) => changeSpecificationHandler(e, schema)}
                />
            </Col>
            <Col md={4} className={'d-flex align-items-end mb-2'}>
                <input
                    type="text"
                    value={schema?.value}
                    name="value"
                    className="form-control w-100"
                    required
                    onChange={(e) => changeSpecificationHandler(e, schema)}
                />
            </Col>
            <Col md={2} className={'d-flex align-items-center justify-content-between'}>
                <div className="form-check form-switch">
                    <input
                        className="form-check-input switch-custom"
                        type="checkbox"
                        name="bold"
                        checked={schema?.bold}
                        onChange={(e)=> switchHandler(e, schema)}
                        role="switch"
                        id="bold"
                    />
                </div>
                <button onClick={() => handleDeleteSpecification(schema)}
                        className="btn btn-danger btn-sm ms-1 mb-1"
                        type={'button'}>
                    {t('global.delete')}
                </button>
            </Col>
        </Row>
    );
};

export default DraggableSchema;