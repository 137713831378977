import {Card, Col, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {PICTURE_URL} from "../../../helpers/api.routes";
import React from "react";
import {useNavigate} from "react-router-dom";

export const CreateForm = ({
                               state,
                               setState,
                               setDocument,
                           }: any) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const changeHandler = (event: any) => {
        const {name, value} = event.target;
        setState((prev: any) => ({...prev, [name]: value}));
    };
    const switchHandler = (e: any) => {
        setState((prev: any) => ({...prev, [e.target.name]: e.target.checked}));
    };

    const fileHandler = (e: any) => {
        setDocument(e.target.files[0]);
    };

    return (
        <Card>
            <Card.Body>
                <Row>
                    <Col md={8} className="mb-2">
                        <label htmlFor="title" className="required-field">
                            {t("global.title")}
                        </label>
                        <input
                            type="text"
                            name="title"
                            className="form-control"
                            required
                            onChange={(e) => changeHandler(e)}
                            value={state?.id && state?.title}
                        />
                    </Col>
                    <Col md={4}>
                        <div className="form-check form-switch p-0">
                            <label
                                className="d-block text-start"
                                htmlFor="isActiveSwitchCheckDefault"
                            >
                                {t("global.isActive")}
                            </label>
                            <div className="row">
                                <input
                                    className="form-check-input switch-custom ms-4 mt-2"
                                    type="checkbox"
                                    name="isActive"
                                    checked={state?.isActive}
                                    onChange={switchHandler}
                                    role="switch"
                                    id="isActiveSwitchCheckDefault"
                                />
                            </div>
                        </div>
                    </Col>
                    <Col md={12}>
                        <div className="">
                            <label htmlFor="documentFile">{t("download.document")}</label>
                            <input
                                onChange={fileHandler}
                                className="form-control"
                                type="file"
                                id="documentFile"
                                accept="application/*"
                            />
                        </div>
                        {state?.document && (
                            <div className="d-flex align-items-center mt-3">
                                <i className="bi bi-file-earmark display-3"></i>
                                <a target="_blank"
                                   rel="noreferrer"
                                   download
                                   href={PICTURE_URL + state.document.path}
                                >
                                    {state.document.name}
                                </a>
                            </div>
                        )}
                    </Col>
                </Row>
                <div className="col-12 my-2 d-flex justify-content-end text-align-center">
                    <div>
                        <button className="btn btn-info ms-3" type="button" onClick={()=> navigate(-1)}>
                            {t(`global.cancel`)}
                        </button>
                        <button className="btn btn-primary ms-3" type="submit">
                            {t(`global.${state?.id ? 'update' : 'create'}`)}
                        </button>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
};
