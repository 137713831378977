import React, {useEffect, useState} from "react";
import {Card, Col, Row} from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import Select from "react-select";
import {ErrorToast, SuccessToast} from "../../../shared/toasters/toasters";
import {IForm} from "../interface";
import {AdminEventSummaryService} from "../service";
import {ISelect} from "../../../shared/interface";
// import { N1ED } from "@edsdk/n1ed-react";
// import { Editor } from "tinymce";
import {CustomImageHandler} from "../../../shared/components/CustomImageHandler";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
import {InitEditor} from "../../tests/components/CreateForm";
import {ConfirmDelete} from "../../../shared/components/ConfirmDelete";
// @ts-ignore
// import Editor from "ckeditor5-custom-build/build/ckeditor";
export const CreateForm = ({
  state,
  setImage,
  setLogo,
  additionalImage,
  setAdditionalImage,
  events,
  setState,
}: IForm) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectEditor, setSelectEditor] = useState<boolean>(false);
  const [mainImage, setMainImage] = useState<any>("");
  const [mainLogo, setMainLogo] = useState<any>("");
  const [selectedPrompt, setSelectedPrompt] = useState<any>();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const changeHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };
  const handleMainImage = (e: any) => {
    setImage(e.target.files[0]);
    setMainImage(URL.createObjectURL(e.target.files[0]!));
  };
  const handleMainLogo = (e: any) => {
    setLogo(e.target.files[0]);
    setMainLogo(URL.createObjectURL(e.target.files[0]!));
  };
  const additionalImageHandler = (e: any) => {
    setAdditionalImage(e.target.files);
  };

  const fileHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    if (field === "logo") {
      setLogo(e.target.files![0]);
    } else {
      setImage(e.target.files![0]);
    }
  };
  const selectHandler = (e: ISelect | null) => {
    setState((prev) => ({ ...prev, event: e!.value }));
  };

  const deleteHandler = (selectedPrompt: any) => {
    selectedPrompt?.id &&
    AdminEventSummaryService.deleteAdditional(selectedPrompt?.id)
        .then((response) => {
          if (response?.status === "success") {
            setState((prev: any) => ({
              ...prev,
              additionalImages: prev.additionalImages.filter((item: any) => item.id !== selectedPrompt.id)}))
            SuccessToast(t(`gallery.successfully_deleted`));
          }
        })
        .catch((error) => ErrorToast(error));
  };
  const handleText = (content: string) => {
    setState((prev: any) => ({ ...prev, content }));
  };
  const switchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({ ...prev, [e.target.name]: e.target.checked }));
  };
  const mappedData = events?.map((event) => ({
    label: event.title,
    value: event.id,
  }));
  useEffect(() => {
    if (!!Number(id)) {
      AdminEventSummaryService.getSingle(id as unknown as number)
        .then((response) => {
          const { data } = response;

          setState({ ...data, event: data.event.id });
          InitEditor(data?.content, handleText);
          window.scrollTo(0, 0);
        })
        .catch((error) => ErrorToast(error));
    } else {
      InitEditor(undefined, handleText);
    }
  }, [id]);
  function removeElementsByClass(className: string) {
    const elements = document.getElementsByClassName(className);
    while (elements.length > 0) {
      elements && elements[0]?.parentNode?.removeChild(elements[0]);
    }
    setSelectEditor(false);
  }

  useEffect(() => {
    if (selectEditor) {
      removeElementsByClass(
        "jsplus_theme jsplus_ui_full_screen jsplus_ui_sidebar_widgets jsplus_show"
      );
    }
  }, [selectEditor]);
  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={6} className="mb-3">
            <label htmlFor="title" className="required-field">
              {t("global.title")}
            </label>
            <input
              type="text"
              name="title"
              className="form-control"
              required
              onChange={changeHandler}
              value={state?.id && state?.title}
            />
          </Col>
          <Col md={6}>
            <label htmlFor="company" className="required-field">
              {t("global.company")}
            </label>
            <input
              type="text"
              name="company"
              className="form-control"
              onChange={changeHandler}
              value={state?.id && state?.company}
            />
          </Col>
          <Col md={6}>
            <label htmlFor="url" className="required-field">
              {t("table_headers.url")}
            </label>
            <input
              type="text"
              name="url"
              className="form-control"
              onChange={changeHandler}
              value={state?.id && state?.url}
            />
          </Col>
          <Col md={4}>
            <label htmlFor="event" className="required-field">
              {t("global.event")}
            </label>
            <Select
              className="react-select-field"
              onChange={selectHandler}
              options={mappedData}
              value={mappedData?.find(
                (category: any) => category.value === state?.event
              )}
            />
          </Col>

          <Col md={2} className="">
            <div className="form-check form-switch p-0">
              <label
                className="d-block text-start"
                htmlFor="flexSwitchCheckDefault"
              >
                {t("global.active")}
              </label>
              <div className="row">
                <input
                  className="form-check-input switch-custom ms-4 mt-2"
                  type="checkbox"
                  name="isActive"
                  checked={state?.isActive}
                  onChange={switchHandler}
                  role="switch"
                  id="flexSwitchCheckDefault"
                />
              </div>
            </div>
          </Col>
          <Col md={12}>
            <label className="d-block text-start" htmlFor="content">
              {t("global.content")}
            </label>

            <textarea className="editor content" onChange={console.log}></textarea>
          </Col>
          <Col md={4} className="my-3">
            <label className="d-block text-start" htmlFor="content">
              {t("global.image")}
            </label>
            <div className="d-flex justify-content-between">
              <div className="profile-user position-relative d-inline-block mx-auto">
                {mainImage || (state?.image as any)?.imagePath ? (
                  <CustomImageHandler
                    path={
                      mainImage ? mainImage : (state?.image as any)?.imagePath
                    }
                    classes="avatar-xl img-thumbnail user-profile-image mx-auto"
                    alt="user-profile-image"
                  />
                ) : (
                  <img
                    src="/assets/images/img-placeholder.png"
                    className="avatar-xl img-thumbnail user-profile-image"
                    alt="user-profile-image"
                  />
                )}
                <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                  <input
                    name="path"
                    type="file"
                    accept="image/*"
                    id="main"
                    // ref={imageRef}
                    className="profile-img-file-input "
                    onChange={(e) => {
                      fileHandler(e, "image");
                      handleMainImage(e);
                    }}
                  />
                  <label
                    // htmlFor="profile-img-file-input"\
                    htmlFor="main"
                    className="profile-photo-edit avatar-xs"
                  >
                    <span className="avatar-title rounded-circle bg-light text-body">
                      <i className="ri-camera-fill" />
                    </span>
                  </label>
                </div>
              </div>
            </div>
            {/* <div className="mt-3">
              <label htmlFor="imageFile"> {t("download.image")}</label>
              <input
                onChange={fileHandler}
                className="form-control"
                type="file"
                accept="image/*"
                id="imageFile"
              />
            </div>
            {state?.image && (
              <div className="col-md-6 py-3">
                <img
                  className="rounded"
                  alt="200x200"
                  width={200}
                  src={PICTURE_URL + state?.image.imagePath}
                  data-holder-rendered="true"
                />
              </div>
            )} */}
          </Col>
          <Col md={8} className="my-3">
            <div>
              <label htmlFor="imageLegend" className="form-label">
                {t("admin_event_summary.image_legend")}
              </label>
              <textarea
                className="form-control"
                id="imageLegend"
                rows={3}
                name="imageLegend"
                value={state?.imageLegend}
                onChange={changeHandler}
              ></textarea>
            </div>
          </Col>
        </Row>
        <Col md={4} className="my-3">
          <label className="d-block text-start" htmlFor="content">
            {t("global.logo")}
          </label>
          <div className="d-flex justify-content-between">
            <div className="profile-user position-relative d-inline-block mx-auto">
              {mainLogo || (state?.logo as any)?.logoPath ? (
                <CustomImageHandler
                  path={mainLogo ? mainLogo : (state?.logo as any)?.logoPath}
                  classes="avatar-xl img-thumbnail user-profile-image mx-auto"
                  alt="user-profile-image"
                />
              ) : (
                <img
                  src="/assets/images/img-placeholder.png"
                  className="avatar-xl img-thumbnail user-profile-image"
                  alt="user-profile-image"
                />
              )}
              <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                <input
                  name="path"
                  type="file"
                  accept="image/*"
                  id="logo"
                  // ref={imageRef}
                  className="profile-img-file-input "
                  onChange={(e) => {
                    fileHandler(e, "logo");
                    handleMainLogo(e);
                  }}
                />
                <label
                  // htmlFor="profile-img-file-input"\
                  htmlFor="logo"
                  className="profile-photo-edit avatar-xs"
                >
                  <span className="avatar-title rounded-circle bg-light text-body">
                    <i className="ri-camera-fill" />
                  </span>
                </label>
              </div>
            </div>
          </div>
          {/* <div className="mt-3">
              <label htmlFor="imageFile"> {t("download.image")}</label>
              <input
                onChange={fileHandler}
                className="form-control"
                type="file"
                accept="image/*"
                id="imageFile"
              />
            </div>
            {state?.image && (
              <div className="col-md-6 py-3">
                <img
                  className="rounded"
                  alt="200x200"
                  width={200}
                  src={PICTURE_URL + state?.image.imagePath}
                  data-holder-rendered="true"
                />
              </div>
            )} */}
        </Col>
        <Col md={12} className="my-3">
          <div className="mb-3">
            <label htmlFor="additionalImage">{t("download.image")}</label>
            <input
                onChange={additionalImageHandler}
                multiple={true}
                className="form-control"
                type="file"
                accept="image/*"
                id="additionalImage"
            />
          </div>
          {state?.additionalImages?.map(additionalImage => {
            return (
                <div className={'d-inline-block position-relative'}>
                  <CustomImageHandler
                      key={additionalImage?.id}
                      path={additionalImage?.path}
                      alt={additionalImage?.name}
                      classes="img-thumbnail mx-auto p-0"
                      style={{width: 145, height: 145, objectFit: 'cover'}}
                  />
                  <i onClick={() => {
                    handleShow();
                    setSelectedPrompt(additionalImage);
                  }}
                     className={'ri-delete-bin-fill bg-danger text-white delete-icon-position'}></i>
                </div>
            )
          })}
          {Array.from(additionalImage)?.length ?
              <div className="border-top mt-4 pt-4 d-flex align-items-center flex-wrap">
                {Array.from(additionalImage)?.map(img => {
                  return (
                      <div key={String(img)}>
                        <CustomImageHandler
                            path={URL.createObjectURL(img)}
                            alt="File to upload"
                            classes="img-thumbnail mx-auto p-0"
                            style={{width: 145, height: 145, objectFit: 'cover'}}
                        />
                      </div>
                  )
                })}
              </div> : ''}
        </Col>

        <div className="col-12 my-2 d-flex justify-content-end text-align-center">
          <div>
            <button
              className="btn btn-info"
              type={'button'}
              onClick={() => {navigate(-1);}}
            >
              {t("global.cancel")}
            </button>
            <button className="btn btn-primary ms-3" type="submit">
              {state?.id ? `${t("global.update")}` : `${t("global.create")}`}
            </button>
          </div>
        </div>
      </Card.Body>
      <ConfirmDelete
          show={show}
          setShow={setShow}
          itemName={selectedPrompt?.name}
          deleteHandler={deleteHandler}
          product={selectedPrompt}
          selectedProduct={selectedPrompt}
      />
    </Card>
  );
};
