import React, {useEffect, useState} from "react";
import {ErrorToast, SuccessToast} from "../../shared/toasters/toasters";
import {AdminNewsService} from "./service";
import {List} from "./components/List";
import {useTranslation} from "react-i18next";
import {AdminMagazineCategoryService} from "../magazineCategory/service";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import DraggableBootstrapTable from "../../shared/components/draggableList/Draggable";
import {Pagination} from "../../shared/paginations/Paginations";
import Select, {SingleValue} from "react-select";
import {AdminCategoryService} from "../category/service";

export const AdminNews = () => {
  const perPageOptions = [
    {
      label: 25,
      value: 25,
    },
    {
      label: 50,
      value: 50,
    },
    {
      label: 100,
      value: 100,
    },
    {
      label: 200,
      value: 200,
    },
    {
      label: 400,
      value: 400,
    },
  ];
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleShow = () => setShow(true);
  const [selectedPrompt, setSelectedPrompt] = useState<any>();
  const [categories, setCategories] = useState();
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [state, setState] = useState<any>();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const domain = useSelector((state: any) => state.company.clientDomain);
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 25,
    term: "",
  });
  const [list, setList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  const mappedDataCategory = categoryList?.map((position: any)=> ({
    label: position.name,
    value: position.id
  }))
  const isElectricWow = localStorage.getItem("website") === "electric_wow";

  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };
  const editHandler = (id: number): void => {
    navigate(`/admin/news/update/${id}`);
  };
  const updatePosition = (newList: any) => {
    const newListUpdated = newList?.map((item: any) => item.id);
    AdminNewsService.updatePosition({ ids: newListUpdated });
  };
  const deleteHandler = () => {
    selectedPrompt?.id &&
      AdminNewsService.delete(selectedPrompt?.id)
        .then((response) => {
          if (response?.status === "success") {
            SuccessToast(t(`news.successfully_deleted`));
            setTriggerUpdate((prev: boolean) => !prev);
          }
        })
        .catch((error) => ErrorToast(error));
  };

  const toggleIsActive = (id: number) => {
    id &&
      AdminNewsService.toggleIsActive(id)
        .then((response) => {
          if (response?.status === "success") {
            SuccessToast(t(`news.successfully_updated`));
            setList(
              (prevState) =>
                prevState.map((listItem: any) => {
                  if (listItem?.id === id) {
                    return {
                      ...listItem,
                      isActive: !listItem.isActive,
                    };
                  }
                  return listItem;
                }) as []
            );
          }
        })
        .catch((error) => ErrorToast(error));
  };
  const handlePerPage = (e: any) => {
    setPagination((prev) => ({ ...prev, page: 1, perPage: e.value }));
  };

  const handleSelectFilters = (
      selectedSingle: SingleValue<{ label: string; value: number }>,
      field: string
  ) => {
    if (selectedSingle?.value as number) {
      setPagination((prev: any) => ({
        ...prev,
        [field]: selectedSingle?.value,
        page: 1,
      }));
    } else {
      setPagination((prev: any) => ({
        ...prev,
        [field]: null,
        page: 1,
      }));
    }
  };

  useEffect(() => {
    if (isElectricWow){
      AdminCategoryService.getAll({ perPage: 0, page: 1 })
          .then((response) => {
            const { data } = response;
            setCategoryList(data?.items);
          })
          .catch((error) => ErrorToast(error));
    }
    AdminNewsService.getAll(pagination)
      .then((response) => {
        const { data } = response;
        setList(data?.items);
        setPage(data?.page);
        setTotalPages(Math.ceil(data?.totalItems / data?.perPage));
      })
      .catch((error) => ErrorToast(error));
  }, [isElectricWow, triggerUpdate, pagination]);

  useEffect(() => {
    AdminMagazineCategoryService.getAll({ page: 1, perPage: 0 })
      .then((response) => {
        const { data } = response;
        setCategories(data?.items);
      })
      .catch((error) => ErrorToast(error));
  }, []);

  return (
    <>
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body d-flex align-items-center">
            <div className="d-flex gap-3">
              <div className="search-box">
                <input
                  type="text"
                  className="form-control
                                    "
                  id="searchMemberList"
                  onChange={(e: any) =>
                    setPagination((prev: any) => ({
                      ...prev,
                      page: 1,
                      term: e.target.value,
                    }))
                  }
                  placeholder={t("global.search")}
                />
                <i className="ri-search-line search-icon"></i>
              </div>
              <div className="d-flex align-items-centr">
                <h6 className="my-auto me-2 p-0 ">Per page:</h6>
                <Select
                  className="react-select-field"
                  onChange={handlePerPage}
                  placeholder="Per page"
                  options={perPageOptions}
                  defaultValue={perPageOptions[0]}
                />
              </div>
              {isElectricWow &&
                  <Select
                      className="react-select-field"
                      isClearable
                      onChange={(e) => {
                        handleSelectFilters(
                            e as SingleValue<{ label: string; value: number }>,
                            "category"
                        );
                      }}
                      options={mappedDataCategory}
                      isSearchable={false}
                      placeholder={t("global.category")}
                      required
                  />
              }
            </div>

            <div className="col-sm-auto ms-auto">
              <div className="list-grid-nav hstack gap-1">
                <button
                  className="btn btn-info"
                  onClick={() => navigate("/admin/news/create")}
                >
                  {t("global.add")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {page > 1 || ((pagination as any)?.term !== "") || (!(pagination as any)?.category && isElectricWow) ? (
        <>
          <div className="col-12">
            <List
              page={page}
              handlePages={handlePages}
              totalPages={totalPages}
              list={list}
              setTriggerUpdate={setTriggerUpdate}
              editHandler={editHandler}
              domain={domain}
              toggleIsActive={toggleIsActive}
              isElectricWow={isElectricWow}
            />
          </div>{" "}
        </>
      ) : (
        <>
          <div className="col-12">
            <DraggableBootstrapTable
              preview={false}
              list={list}
              setList={setList}
              headers={["id", "title", "isActive", isElectricWow ? 'categories' : ''].filter(type=> !!type.length)}
              title="news"
              onEdit={editHandler}
              selectedPrompt={selectedPrompt}
              setSelectedPrompt={setSelectedPrompt}
              handleShow={handleShow}
              show={show}
              setShow={setShow}
              deleteHandler={deleteHandler}
              updatePosition={updatePosition}
              toggleIsActive={toggleIsActive}
            />
          </div>
        </>
      )}

      {page && (
        <div className="d-flex justify-content-end ">
          {totalPages <= 1 ? (
            ""
          ) : (
            <Pagination
              page={page}
              totalPages={totalPages}
              handlePagination={handlePages}
            />
          )}
        </div>
      )}
    </>
  );
};
