import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ErrorToast, SuccessToast} from "../../../shared/toasters/toasters";
import {AdminUserRegistrationService} from "../service";
import {Form} from "react-bootstrap";
import CreateForm, {IUserRegistration} from "./CreateForm";

const UpdateUserRegistration = () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const {t} = useTranslation();
    const [state, setState] = useState<IUserRegistration | null>(null);

    const submitHandler = () => {
        state?.id && AdminUserRegistrationService.update(
            {
                ...state,
                cars: (state as IUserRegistration)?.cars?.map((car: { value: number }) => car?.value) as number[]
            }, state.id)
            .then((response) => {
                SuccessToast(t(`user_registration.successfully_updated`));
                navigate(-1);
                setState(null);
            })
            .catch((error) => ErrorToast(error));
    };

    useEffect(() => {
        if (id) {
            AdminUserRegistrationService.getSingle(Number(id))
                .then(response => {
                    setState({
                        ...response?.data,
                        cars: response?.data?.cars?.map((car: { id: number, title: string }) => ({
                            label: car?.title,
                            value: car.id
                        }))
                    })
                }).catch(err => ErrorToast(err))
        }
    }, [id])

    return (
        <div className="col-12">
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    submitHandler();
                }}
            >
                <CreateForm state={state} setState={setState}/>
            </Form>
        </div>
    );
};

export default UpdateUserRegistration;