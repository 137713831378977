import {NEWS_URL} from "../../helpers/api.routes";
import {requests} from "../../helpers/api.services";

export const AdminNewsService = {
  getAll: (params: any) => requests.get(`${NEWS_URL}`, params),
  getAllAuthors: () => requests.get(`${NEWS_URL}/authors`),
  create: (params: any) => requests.post(`${NEWS_URL}`, params, true),
  getSingle: (id: number) => requests.get(`${NEWS_URL}/${id}`),
  update: (params: any, id: number) =>
    requests.post(`${NEWS_URL}/${id}`, params, true),
  delete: (id: number) => requests.delete(`${NEWS_URL}/${id}`),
  deleteAdditional: (id: number) =>
    requests.delete(`${NEWS_URL}/additional-file/${id}`),
  updateFeatured: (id: number) => requests.put(`${NEWS_URL}/featured/${id}`),
  copy: (params: any, id: number) =>
    requests.post(`${NEWS_URL}/copy/${id}`, params),
  updatePosition: (params: any) =>
    requests.patch(`${NEWS_URL}/sort-latest`, params),
  toggleIsActive: (id: number) => requests.patch(`${NEWS_URL}/activate-deactivate/${id}`),
  updateOnFirstPosition: (id: number) => requests.patch(`${NEWS_URL}/move-to-first-position/${id}`, true)
};
