import React from 'react';
import {useTranslation} from "react-i18next";
import {downloadFile} from "../../../helpers/api.services";

const UserExport = () => {
    const {t} = useTranslation();
    const downloadXLSFile = async () => {
        await downloadFile({
            route: `${
                process.env.NODE_ENV === "development"
                    ? process.env.REACT_APP_DEV_MODE
                    : process.env.REACT_APP_PRO_MODE
            }admin/user-registration/download`,
            fileName: 'download',
        })
    };

    return (
        <div className="card">
            <div className="card-body">
                <div className="d-flex justify-content-center align-items-center">
                    <button
                        className="btn btn-success ms-2"
                        onClick={() => downloadXLSFile()}
                    >
                        {t("global.export")}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default UserExport;