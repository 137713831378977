import React, {useEffect, useState} from "react";
import {Card, Col, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import Select from "react-select";
import {MenuPositionService} from "../../menuPosition/service";
import {ErrorToast} from "../../../shared/toasters/toasters";

export const CreateForm = ({ setIsMenuOpened, state, setState, list }: any) => {
  const { t } = useTranslation();
  const [positions, setPositions] = useState<any>([{}]);
  const changeHandler = (event: any) => {
    const { name, value } = event.target;
    setState((prev: any) => ({ ...prev, [name]: value }));
  };

  const selectHandler = (e: any) => {
    setState((prev: any) => ({ ...prev, positionMenu: e.value }));
  };

  const switchHandler = (e: any) => {
    setState((prev: any) => ({ ...prev, [e.target.name]: e.target.checked }));
  };

  const mappedData = positions?.map((position: any) => ({
    label: position.location,
    value: position.id,
  }));

  useEffect(() => {
    MenuPositionService.getAll({ perPage: 0, page: 1 })
      .then((response) => {
        const { data } = response;

        setPositions(data.items);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={3}>
            <label htmlFor="name" className="required-field">
              {t("global.name")}
            </label>
            <input
              type="text"
              name="name"
              className="form-control"
              required
              onChange={(e) => changeHandler(e as any)}
              value={state?.id && state?.name}
            />
          </Col>
          <Col md={3}>
            <label htmlFor="name" className="required-field">
              {t("global.url")}
            </label>
            <input
              type="text"
              name="url"
              className="form-control"
              required
              onChange={(e) => changeHandler(e as any)}
              value={state?.id && state?.url}
            />
          </Col>
          <Col md={2}>
            <label htmlFor="position" className="required-field">
              {t("table_headers.positionMenu")}
            </label>
            <Select
              className="react-select-field"
              onChange={selectHandler}
              options={mappedData}
              required
              value={mappedData?.find(
                (position: any) => position.value === state?.positionMenu?.id
              )}
            />
          </Col>

          <Col md={2} className="">
            <div className="form-check form-switch p-0">
              <label
                className="d-block text-start"
                htmlFor="flexSwitchCheckDefault"
              >
                {t("menu.is_target_blank")}
              </label>
              <div className="row">
                <input
                  className="form-check-input switch-custom ms-2 mt-2"
                  type="checkbox"
                  name="isTargetBlank"
                  checked={state?.isTargetBlank}
                  onChange={switchHandler}
                  role="switch"
                  id="flexSwitchCheckDefault"
                />
              </div>
            </div>
          </Col>
          <Col md={2}>
            <div className="form-check form-switch">
              <label
                  className="d-block text-start"
                  htmlFor="flexSwitchCheckDefault"
              >
                {t("global.isActive")}
              </label>
              <div className="row">
                <input
                    className="form-check-input switch-custom ms-2 mt-2"
                    type="checkbox"
                    name="isActive"
                    checked={state?.isActive}
                    onChange={switchHandler}
                    role="switch"
                    id="flexSwitchCheckDefault"
                />
              </div>
            </div>
          </Col>
        </Row>
        <div className="col-12 my-2 d-flex justify-content-end text-align-center">
          <div>
            <button
              className="btn btn-info"
              onClick={() => {
                setIsMenuOpened(false);
                setState({} as any);
              }}
            >
              {t("global.cancel")}
            </button>
            <button className="btn btn-primary ms-3" type="submit">
              {state?.id ? `  ${t("global.update")}` : `${t("global.create")}`}
            </button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
