import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
// import { N1ED } from "@edsdk/n1ed-react";
// import { Editor } from "tinymce";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PICTURE_URL } from "../../../helpers/api.routes";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
import { editorConfigurationCustom } from "../../../shared/functions/editorConfigurationCustom";
import { AdminBrandService } from "../service";
import { ErrorToast } from "../../../shared/toasters/toasters";
import { InitEditor } from "../../tests/components/CreateForm";
// @ts-ignore
// import Editor from "ckeditor5-custom-build/build/ckeditor";
export const CreateForm = ({ state, setState, image, setImage }: any) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const firstLoad = useRef(true);
  const [editorData, setEditorData] = useState<any>();
  const [selectEditor, setSelectEditor] = useState<boolean>(false);
  // const changeEditorHandler = (content: string, editor: Editor) => {
  //   setState((prev: any) => ({ ...prev, [editor?.id]: content }));
  //   if (!firstLoad.current) {
  //     setEditorData(editor);
  //   }
  //   firstLoad.current = false;
  // };
  const changeHandler = (event: any) => {
    const { name, value } = event.target;
    setState((prev: any) => ({ ...prev, [name]: value }));
  };
  const changeHandlerEditor = (value: string, eventName: string) => {
    setState((prev: any) => ({ ...prev, [eventName]: value }));
  };
  const fileHandler = (e: any) => {
    setImage(e.target.files[0]);
  };
  const handleText = (description: string) => {
    setState((prev: any) => ({ ...prev, description }));
  };
  useEffect(() => {
    if (Number(id)) {
      AdminBrandService.getSingle(Number(id))
        .then((response) => {
          const { data } = response;
          setState(data);
          InitEditor(data?.description, handleText);
          window.scrollTo(0, 0);
        })
        .catch((error) => ErrorToast(error));
    } else {
      InitEditor(undefined, handleText);
    }
  }, [id]);
  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={4}>
            <label htmlFor="name" className="required-field">
              {t("global.name")}
            </label>
            <input
              type="text"
              name="name"
              className="form-control"
              required
              onChange={(e) => changeHandler(e as any)}
              value={state?.id && state?.name}
            />
          </Col>
          <Col md={4}>
            <label htmlFor="name" className="required-field">
              {t("global.link")}
            </label>
            <input
              type="text"
              name="link"
              className="form-control"
              required
              onChange={(e) => changeHandler(e as any)}
              value={state?.id && state?.link}
            />
          </Col>
          <Col md={12} className="mt-2">
            <div>
              {/* <label className="d-block text-start" htmlFor="content">
                {t("global.description")}
              </label>

              <textarea className="editor" onChange={console.log}></textarea> */}
              {/* <N1ED
                id={`description`}
                onFocus={(e) => setSelectEditor(true)}
                apiKey={process.env.REACT_APP_EDITOR_KEY}
                initialValue={
                  editorData ? editorData : state ? state?.description : ""
                }
                onEditorChange={changeEditorHandler}
              /> */}
              {/* <CKEditor
                config={editorConfigurationCustom}
                editor={Editor}
                data={state?.description ?? ""}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  changeHandlerEditor(data, "description");
                }}
              /> */}
            </div>
          </Col>
          <div className="mt-3">
            <label htmlFor="imageFile"> {t("download.image")}</label>
            <input
              onChange={fileHandler}
              className="form-control"
              type="file"
              accept="image/*"
              id="imageFile"
            />
          </div>
          {state?.image && (
            <img
              className="mt-3 rounded show-img-form"
              alt="200x200"
              src={PICTURE_URL + state?.image.path}
              data-holder-rendered="true"
            />
          )}
        </Row>
        <div className="col-12 my-2 d-flex justify-content-end text-align-center">
          <div>
            <button
              className="btn btn-info"
              type={'button'}
              onClick={() => {
                navigate(-1);
                setState({} as any);
              }}
            >
              {t("global.cancel")}
            </button>
            <button className="btn btn-primary ms-3" type="submit">
              {state?.id ? `  ${t("global.update")}` : `${t("global.create")}`}
            </button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
