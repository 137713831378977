import {MEDIADATEN} from "../../helpers/api.routes";
import {requests} from "../../helpers/api.services";
import {IPagination} from "../model/interface";

export const AdminMediadatenService = {
  getAll: (params: IPagination) => requests.get(MEDIADATEN, params),
  create: (params: FormData) => requests.post(MEDIADATEN, params, true),
  getSingle: (id: number) => requests.get(`${MEDIADATEN}/${id}`),
  update: (id: number, payload: any) => requests.post(`${MEDIADATEN}/${id}`, payload),
  delete: (id: number) => requests.delete(`${MEDIADATEN}/${id}`),
  updatePosition: (params: any) => requests.patch(`${MEDIADATEN}`, params),
};
