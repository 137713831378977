import React, {useEffect, useState} from 'react';
import {Card, Col, Form, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {ErrorToast, SuccessToast} from "../../shared/toasters/toasters";
import {AdminTargetGroupService} from "./service";
import {PICTURE_URL} from "../../helpers/api.routes";
import {ConfirmDelete} from "../../shared/components/ConfirmDelete";
import {HTML5Backend} from "react-dnd-html5-backend";
import DraggableSchema from "./components/DraggableSchema";
import {DndProvider} from "react-dnd";

interface ITargetGroup {
    title: string;
    id?: number;
    specificationData?: ISpecificationData[],
    image?: { path: string }
}

export interface ISpecificationData {
    id?: number;
    value?: string;
    index?: string;
    indicator?: number
}

const AdminTargetGroup = () => {
    const {t} = useTranslation();
    const [state, setState] = useState<ITargetGroup | null>(null);
    const [image, setImage] = useState<File | null>(null);
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setState((prev) => ({...prev, [name]: value}) as ITargetGroup);
    };

    const fileHandler = (e: any) => {
        setImage(e.target.files[0]);
    };
    const changeSpecificationHandler = (event: React.ChangeEvent<HTMLInputElement>, schema: ISpecificationData) => {
        const {name, value} = event.target;
        const updated = state?.specificationData?.map(programSch => {
            if (programSch?.indicator === schema?.indicator) {
                return {
                    ...programSch,
                    [name]: value
                }
            }
            return programSch
        });
        setState(prev => ({...prev, specificationData: updated}) as ITargetGroup)
    }

    const handleDeleteSpecification = async (specification?: ISpecificationData) => {
        if (!specification?.id) {
            setState((prev: any) => ({
                ...prev,
                specificationData: prev?.specificationData?.filter((schema: ISpecificationData) => schema?.indicator !== specification?.indicator)
            }))
        } else {
            await AdminTargetGroupService.deleteSpecification(specification?.id)
                .then(response => {
                    if (response) {
                        setState((prev: any) => ({
                            ...prev,
                            specificationData: prev?.specificationData?.filter((schema: ISpecificationData) => schema?.indicator !== specification?.indicator)
                        }))
                    }
                }).catch(err => ErrorToast(err));
        }
    }
    const deleteImageHandler = () => {
        AdminTargetGroupService.deleteImage()
            .then((response) => {
                if (response?.status === "success") {
                    SuccessToast(t(`target_group.successfully_deleted_image`));
                    setState(response.data);
                }
            })
            .catch((error) => ErrorToast(error));
    };

    const updateList = (newList: ISpecificationData[]) => {
        setState(prev => ({...prev, specificationData: newList}) as ITargetGroup)
    }

    const updatePosition = (newList: any) => {
        const newListUpdated = newList?.map((item: any) => item.id);
        AdminTargetGroupService.updatePosition(newListUpdated);
    };
    const submitHandler = () => {
        const formData = new FormData();
        formData.append('body', JSON.stringify({
            ...state,
            specificationData: state?.specificationData
                ?.map((item, index) => ({...item, id: item?.id || null, index: index}))
        }));
        image && formData.append("image", image);
        AdminTargetGroupService.create(formData)
            .then((response) => {
                setState({
                    ...response?.data,
                    specificationData: response?.data?.specificationData?.map((schema: ISpecificationData) => ({
                        ...schema,
                        indicator: schema?.id
                    }))
                });
                setImage(null);
                SuccessToast(t(`target_group.successfully_updated`));
            })
            .catch((error) => ErrorToast(error));
    }

    useEffect(() => {
        AdminTargetGroupService.get()
            .then((response) => {
                const {data} = response;
                setState({
                    ...data,
                    specificationData: data?.specificationData?.map((specificationData: ISpecificationData) => ({
                        ...specificationData,
                        indicator: specificationData?.id
                    }))
                });
            })
            .catch((error) => ErrorToast(error));
    }, []);

    return (
        <Card>
            <Card.Body>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault()
                        submitHandler();
                    }}
                >
                    <Row className="my-3">
                        <Col md={4}>
                            <label htmlFor="name" className="required-field">
                                {t("global.title")}
                            </label>
                            <input
                                type="text"
                                value={state?.title}
                                name="title"
                                className="form-control"
                                required
                                onChange={changeHandler}
                            />
                        </Col>
                        <Col md={12} className={'mt-3'}/>
                        {state?.specificationData &&
                            <>
                                <Col md={1}></Col>
                                <Col md={11}>
                                    <label htmlFor="value" className="required-field">
                                        {t("global.value")}
                                    </label>
                                </Col>
                            </>
                        }
                        <Col md={12}>
                            <DndProvider backend={HTML5Backend}>
                                {state?.specificationData?.map((schema, index) => {
                                    return (
                                        <DraggableSchema key={schema?.id} index={index} schema={schema}
                                                         updatePosition={updatePosition}
                                                         updateList={updateList}
                                                         list={state?.specificationData || []}
                                                         changeSpecificationHandler={changeSpecificationHandler}
                                                         handleDeleteSpecification={handleDeleteSpecification}/>
                                    )
                                })}
                            </DndProvider>
                        </Col>
                        <Col md={12} className='d-flex mt-3 justify-content-end'>
                            <button type={'button'} className="btn btn-sm btn-success"
                                    onClick={() => setState((prev: any) => ({
                                        ...prev, specificationData: prev?.specificationData?.concat({
                                            key: '',
                                            indicator: Math.random()
                                        })
                                    }))}>
                                {t("target_group.add_new")}
                            </button>
                        </Col>
                        <div className="mt-3">
                            <label htmlFor="imageFile"> {t("download.image")}</label>
                            <input
                                onChange={fileHandler}
                                className="form-control"
                                type="file"
                                accept="image/*"
                                id="imageFile"
                            />
                        </div>
                        {state?.image && (
                            <div className={'mt-3 w-auto px-0'} key={state?.image?.path}>
                                <img
                                    className="object-fit-cover"
                                    width={250}
                                    height={250}
                                    src={PICTURE_URL + state?.image.path}
                                    data-holder-rendered="true"
                                    alt={state?.image?.path}
                                    style={{margin: '2px'}}
                                />
                                <i onClick={() => {
                                    handleShow();
                                }}
                                   className={'ri-delete-bin-fill bg-danger text-white delete-icon-position'}/>
                            </div>
                        )}
                    </Row>
                    <div className={'d-flex justify-content-end'}>
                        <button className="btn btn-primary" type="submit">
                            {t("global.save")}
                        </button>
                    </div>
                </Form>
            </Card.Body>
            <ConfirmDelete
                show={show}
                setShow={setShow}
                itemName={state?.title}
                deleteHandler={deleteImageHandler}
                product={state}
                selectedProduct={state}
            />
        </Card>
    );
};

export default AdminTargetGroup;