import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { PICTURE_URL } from "../../../helpers/api.routes";
import { ISelect } from "../../../shared/interface";
import { ErrorToast } from "../../../shared/toasters/toasters";
import { AdminProductService } from "../service";
// import { N1ED } from "@edsdk/n1ed-react";
// import { Editor } from "tinymce";
import { AdminProductCategoryService } from "../../productCategory/service";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
import { editorConfigurationCustom } from "../../../shared/functions/editorConfigurationCustom";
import { InitEditor } from "../../tests/components/CreateForm";
// @ts-ignore
// import Editor from "ckeditor5-custom-build/build/ckeditor";
export const CreateForm = ({ state, setImage, categories, setState }: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [editorData, setEditorData] = useState<any>();
  const [subCategory, setSubCategory] = useState<any[]>([]);
  const [selectEditor, setSelectEditor] = useState<boolean>(false);
  const firstLoad = useRef(true);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setState((prev: any) => ({ ...prev, [name]: value }));
  };

  const fileHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.files && setImage(e.target.files[0]);
  };
  // const changeEditorHandler = (content: string, editor: Editor) => {
  //   setState((prev: any) => ({ ...prev, [editor?.id]: content }));
  //   if (!firstLoad.current) {
  //     setEditorData(editor);
  //   }
  //   firstLoad.current = false;
  // };
  const changeHandlerEditor = (value: string, eventName: string) => {
    setState((prev: any) => ({ ...prev, [eventName]: value }));
  };
  const switchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev: any) => ({ ...prev, [e.target.name]: e.target.checked }));
  };

  const selectHandler = (e: ISelect | null) => {
    if (e?.value as number) {
      setState((prev: any) => ({
        ...prev,
        category: e!.value,
        subCategory: null,
      }));
    } else {
      setState((prev: any) => ({ ...prev, category: null, subCategory: null }));
    }
  };
  const selectHandlerSubcategory = (e: ISelect | null) => {
    if (e?.value as number) {
      setState((prev: any) => ({ ...prev, subCategory: e!.value }));
    } else {
      setState((prev: any) => ({ ...prev, subCategory: null }));
    }
  };

  const mappedData = categories?.map((category: any) => ({
    label: category.name,
    value: category.id,
  }));
  const mappedSubCategory = subCategory?.map((sub: any) => ({
    label: sub.name,
    value: sub.id,
  }));

  function handleDate(date: Date) {
    const value = new Date(date);
    value.setHours(12);
    setStartDate(date);
    setState((prev: any) => ({ ...prev, date: value as unknown as string }));
  }
  const handleText = (description: string) => {
    setState((prev: any) => ({ ...prev, description }));
  };
  useEffect(() => {
    if (!!Number(id)) {
      AdminProductService.getSingle(id as unknown as number)
        .then((response) => {
          const { data } = response;
          InitEditor(data?.description, handleText);
          setState({ ...data, category: data.category.id });
          window.scrollTo(0, 0);
        })
        .catch((error) => ErrorToast(error));
    } else {
      InitEditor(undefined, handleText);
    }
  }, [id]);
  function removeElementsByClass(className: string) {
    const elements = document.getElementsByClassName(className);
    while (elements.length > 0) {
      elements && elements[0]?.parentNode?.removeChild(elements[0]);
    }
    setSelectEditor(false);
  }

  useEffect(() => {
    if (selectEditor) {
      removeElementsByClass(
        "jsplus_theme jsplus_ui_full_screen jsplus_ui_sidebar_widgets jsplus_show"
      );
    }
  }, [selectEditor]);
  useEffect(() => {
    state.category &&
      AdminProductCategoryService.getAll({
        page: 1,
        perPage: 0,
        isActive: 1,
        parent: state?.category?.id ?? state?.category,
      })
        .then((response) => {
          const { data } = response;
          setSubCategory(data?.items);
        })
        .catch((error) => ErrorToast(error));
  }, [state.category]);

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={4} className="mb-3">
            <label htmlFor="name" className="required-field">
              {t("global.title")}
            </label>
            <input
              type="text"
              name="title"
              className="form-control"
              required
              onChange={(e) => changeHandler(e)}
              value={state?.id && state.title}
            />
          </Col>
          <Col md={3}>
            <label htmlFor="category" className="required-field">
              {t("table_headers.category")}
            </label>
            <Select
              isClearable
              className="react-select-field"
              onChange={selectHandler}
              options={mappedData}
              value={mappedData?.find(
                (category: any) => category.value === state.category
              )}
            />
          </Col>
          {state.category && subCategory?.length > 0 && (
            <Col md={3}>
              <label htmlFor="subcategory" className="required-field">
                {t("table_headers.subcategory")}
              </label>
              <Select
                isClearable
                className="react-select-field"
                onChange={selectHandlerSubcategory}
                options={mappedSubCategory}
                value={mappedSubCategory?.find(
                  (category: any) => category.value === state.subCategory?.id
                )}
              />
            </Col>
          )}

          <Col md={2} className="form-check form-switch p-0">
            <label
              className="d-block text-start"
              htmlFor="flexSwitchCheckDefault"
            >
              {t("global.active")}
            </label>
            <div className="row">
              <input
                className="form-check-input switch-custom ms-4 mt-2"
                type="checkbox"
                name="isActive"
                checked={state.isActive}
                onChange={switchHandler}
                role="switch"
                id="flexSwitchCheckDefault"
              />
            </div>
          </Col>
          <Col md={12} className="mb-3" id="custom-editor">
            <div>
              <label htmlFor="title" className="required-field">
                {t("global.description")}
              </label>
            </div>
            <textarea className="editor"></textarea>
            {/* <N1ED
              onFocus={(e) => setSelectEditor(true)}
              id={`description`}
              apiKey={process.env.REACT_APP_EDITOR_KEY}
              initialValue={
                editorData ? editorData : state ? state?.description : ""
              }
              onEditorChange={changeEditorHandler}
            /> */}
            {/* <CKEditor
              config={editorConfigurationCustom}
              editor={Editor}
              data={state?.description ?? ""}
              onChange={(event, editor) => {
                const data = editor.getData();
                changeHandlerEditor(data, "description");
              }}
            /> */}
          </Col>
          <Col md={6} className="mb-3">
            <label htmlFor="name" className="required-field">
              {t("product.price")}
            </label>
            <input
              type="number"
              name="price"
              className="form-control"
              required
              onChange={(e) => changeHandler(e)}
              value={state?.id && state.price}
            />
          </Col>
        </Row>

        <div className="mt-3">
          <label htmlFor="imageFile"> {t("download.image")}</label>
          <input
            onChange={fileHandler}
            className="form-control"
            type="file"
            accept="image/*"
            id="imageFile"
          />
        </div>
        {state?.image && (
          <div className="col-md-6 py-3">
            <img
              className="rounded"
              alt="200x200"
              width={200}
              src={PICTURE_URL + state?.image.imagePath}
              data-holder-rendered="true"
            />
          </div>
        )}

        <div className="col-12 my-2 d-flex justify-content-end text-align-center">
          <div>
            <button
              className="btn btn-info"
              onClick={() => {
                navigate("/admin/event");
              }}
            >
              {t("global.cancel")}
            </button>
            <button className="btn btn-primary ms-3" type="submit">
              {state?.id ? `${t("global.update")}` : `${t("global.create")}`}
            </button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
