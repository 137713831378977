import axios, { AxiosResponse } from "axios";
import { StoreKeeper } from "../store";
import { projectVersionSlice } from "../store/slices/project-version.slice";
import {AxiosRequestConfig} from "axios/index";

const language = localStorage.getItem("language");

const defaultOptions = {
  headers: {
    "Content-Type": "application/json",
    "Aw-Web-Site": "aw",
    "project-version": localStorage.getItem("project-version") || "",
  },
};

export const instance = axios.create({
  headers: defaultOptions.headers,
});

instance.interceptors.request.use(function (config) {
  const projectVersion = localStorage.getItem("projectVersion");
  config!.headers!["project-version"] = projectVersion ?? "";
  const website = localStorage.getItem("website");
  config!.headers!["Aw-Web-Site"] = website ?? "aw";
  const token = localStorage.getItem("token") ?? "";
  config!.headers!.Authorization = token ? `Bearer ${token}` : "";

  return config;
});

instance.interceptors.response.use(
  function (config) {
    return config;
  },
  (err) => {
    const { status, data } = err?.response ?? {};
    if (status === 505) {
      StoreKeeper.store.dispatch(
        projectVersionSlice.actions.setReloadModal(true)
      );
      localStorage.setItem("projectVersion", data?.currentVersion ?? "1.0.0");
    }

    if (status === 403) {
      (({}) as any).navigate("/403");
    }
    return Promise.reject(err);
  }
);

const responseBody = (response: AxiosResponse) => response.data;
export const requests = {
  get: (url: string, params?: any) =>
    instance
      .get(url, { params })
      .then(responseBody)
      .catch((e) => {
        throw e;
      }),
  post: (url: string, body: {}, formData?: boolean, onUploadProgress?: any) =>
    instance
      .post(
        url,
        body,
        formData
            ? {headers: {"Content-Type": "multipart/form-data"}, onUploadProgress,}
          : defaultOptions
      )
      .then(responseBody)
      .catch((e) => {
        throw e;
      }),
  put: (url: string, body?: {}, formData?: boolean) =>
    instance
      .put(
        url,
        body,
        formData
          ? { headers: { "Content-Type": "multipart/form-data" } }
          : defaultOptions
      )
      .then(responseBody)
      .catch((e) => {
        throw e;
      }),
  patch: (url: string, body?: {}) =>
    instance
      .patch(url, body)
      .then(responseBody)
      .catch((e) => {
        throw e;
      }),
  delete: (url: string, body?: {}) =>
    instance
      .delete(url, { data: body })
      .then(responseBody)
      .catch((e) => {
        throw e;
      }),
};

export function multipartFormData(
  files: any,
  url: string,
  body?: any,
  method = "put",
  fileKey = "image"
  //fileKey = "image" or fileKey = "file"
) {
  const formData = new FormData();
  if (files?.length) {
    for (let file in files) {
      formData.append(fileKey, files[file]);
    }
  } else {
    formData.append(fileKey, files);
  }
  formData.append("body", body);
  if (method === "put") {
    return instance.put(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Aw-Web-Site": "aw",
      },
    });
  } else {
    return instance.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Aw-Web-Site": "aw",
      },
    });
  }
}
export function logoImageFormData(
  files: any,
  logo: any,
  url: string,
  body?: any,
  method = "put",
  fileKey = "image",
  fileKeyLogo = "logo",
  additionalFiles?: any,
  additionalFileKey = "additionalImage",

  //fileKey = "image" or fileKey = "file"
) {
  const formData = new FormData();
  if (files?.length) {
    for (let file in files) {
      formData.append(fileKey, files[file]);
    }
  } else {
    formData.append(fileKey, files);
  }
  if (logo?.length) {
    for (let item in logo) {
      formData.append(fileKeyLogo, logo[item]);
    }
  } else {
    formData.append(fileKeyLogo, logo);
  }

  if (additionalFiles?.length) {
    for (let file in additionalFiles) {
      formData.append(additionalFileKey, additionalFiles[file]);
    }
  } else {
    formData.append(additionalFileKey, additionalFiles);
  }

  formData.append("body", body);
  if (method === "put") {
    return instance.put(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Aw-Web-Site": "aw",
      },
    });
  } else {
    return instance.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Aw-Web-Site": "aw",
      },
    });
  }
}

export function DocImgFormData(
  document: any,
  image: any,
  url: string,
  body?: any,
  method = "put",
  documentTitle = "document"
) {
  const formData = new FormData();
  formData.append(documentTitle, document);
  formData.append("image", image);
  formData.append("body", body);
  if (method === "put") {
    return instance.put(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Aw-Web-Site": "aw",
      },
    });
  } else {
    return instance.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Aw-Web-Site": "aw",
      },
    });
  }
}


export const downloadFile = async ({
                                       route,
                                       fileName = 'download',
                                       params,
                                       responseType = 'arraybuffer',
                                       contentType = 'blob'
                                   }: any) => {
    const website = localStorage.getItem("website");
    const appVersion = localStorage.getItem("appVersion");
    const headers = {
        "Content-Type": contentType,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "app-version": appVersion,
        "Aw-Web-Site": website ?? "aw",
    };
    const config: AxiosRequestConfig = {
        method: "PATCH",
        url: route,
        responseType,
        headers,
        params
    };
    const response = await axios(config);
    const url = URL.createObjectURL(new Blob([(response as any)?.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(fileName, `${Date.now()}.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.remove();
}