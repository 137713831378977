import {useState} from "react";
import {Table} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {ConfirmDelete} from "../../../shared/components/ConfirmDelete";
import {EmptyState} from "../../../shared/components/EmptyState";
import {Loader} from "../../../shared/components/Loader";

export const List = ({list, editHandler, deleteHandler, selectedPrompt, setSelectedPrompt}: any) => {
    const {t} = useTranslation();
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);

    return (
        <div className="card">
            <div className="card-header pb-0">
                <h4 className="card-title mg-b-0">{t("mediadaten.listOfMediadaten")}</h4>
            </div>
            <div className="card-body">
                {list === undefined ? (
                    <Loader/>
                ) : list.length === 0 ? (
                    <EmptyState/>
                ) : (
                    <div className="table-responsive">
                        <Table className="align-middle table-nowrap mb-0">
                            <thead className="table-light">
                            <tr className="">
                                <th scope="col"> {t("global.id")}</th>
                                <th scope="col"> {t("global.title")}</th>
                                <th scope="col"> {t("global.isActive")}</th>
                                <th scope="col" className="text-center">{t("global.actions")}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {list.map((prompt: any) => {
                                return (
                                    <tr key={prompt.id} className="">
                                        <td>
                                            <Link to="#" className="fw-medium">
                                                #{prompt?.id}
                                            </Link>
                                        </td>
                                        <td>{prompt.title ?? "-"}</td>
                                        <td
                                            className={`text-${prompt.isActive ? "success" : "danger"}`}
                                        >
                                            {prompt.isActive ? (
                                                <span>
                                                    <i className="ri-checkbox-circle-line fs-17 align-middle"/>{" "}
                                                    {t("global.active")}
                                                </span>
                                            ) : (
                                                <span>
                                                    <i className="ri-close-circle-line fs-17 align-middle"/>
                                                    {t("global.inactive")}
                                                </span>
                                            )}
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <button
                                                    className="btn btn-sm btn-warning d-flex align-items-center me-2"
                                                    onClick={() => editHandler(prompt.id!)}
                                                >
                                                    <i className="ri-edit-2-fill me-1"/>
                                                    {t("global.edit")}
                                                </button>
                                                <button
                                                    className="btn btn-sm btn-danger d-flex align-items-center"
                                                    onClick={() => {
                                                        handleShow();
                                                        setSelectedPrompt(prompt);
                                                    }}
                                                >
                                                    <i className="ri-delete-bin-fill me-1"/>
                                                    {t("global.delete")}
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </Table>
                    </div>
                )}
                <ConfirmDelete
                    show={show}
                    setShow={setShow}
                    itemName={selectedPrompt?.title}
                    deleteHandler={deleteHandler}
                    product={selectedPrompt}
                    selectedProduct={selectedPrompt}
                />
            </div>
        </div>
    );
};
