import React, {useEffect, useState} from 'react';
import {Card, Col, Form, Row} from "react-bootstrap";
import {ErrorToast, SuccessToast} from "../../shared/toasters/toasters";
import {AdminProgramService} from "./service";
import {useTranslation} from "react-i18next";
import {PICTURE_URL} from "../../helpers/api.routes";
import DraggableSchema from "./components/DraggableSchema";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";

interface IProgram {
    title: string;
    id?: number;
    programSchema?: IProgramSchema[],
    image?: { path: string }
}

export interface IProgramSchema {
    indicator: number;
    id?: number;
    key: string;
    value: string;
    index?: string,
    bold?: boolean,
}

const AdminProgram = () => {
    const {t} = useTranslation();
    const [state, setState] = useState<IProgram | null>(null);
    const [image, setImage] = useState<File | null>(null);
    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setState((prev) => ({...prev, [name]: value}) as IProgram);
    };
    const switchHandler = (e: React.ChangeEvent<HTMLInputElement>,  schema: IProgramSchema) => {
        const {name, checked} = e.target;
        const updated = state?.programSchema?.map(programSch => {
            if (programSch?.indicator === schema?.indicator) {
                return {
                    ...programSch,
                    [name]: checked
                }
            }
            return programSch
        });
        setState(prev => ({...prev, programSchema: updated}) as IProgram)
    };
    const fileHandler = (e: any) => {
        setImage(e.target.files[0]);
    };
    const updatePosition = (newList: any) => {
        const newListUpdated = newList?.map((item: any) => item.id);
        AdminProgramService.updatePosition(newListUpdated);
    };
    const changeSpecificationHandler = (event: React.ChangeEvent<HTMLInputElement>, schema: IProgramSchema) => {
        const {name, value} = event.target;
        const updated = state?.programSchema?.map(programSch => {
            if (programSch?.indicator === schema?.indicator) {
                return {
                    ...programSch,
                    [name]: value
                }
            }
            return programSch
        });
        setState(prev => ({...prev, programSchema: updated}) as IProgram)
    }

    const handleDeleteSpecification = async (specification?: IProgramSchema) => {
        if (!specification?.id) {
            setState((prev: any) => ({
                ...prev,
                programSchema: prev?.programSchema?.filter((schema: IProgramSchema) => schema?.indicator !== specification?.indicator)
            }))
        } else {
            await AdminProgramService.delete(specification?.id)
                .then(response => {
                    if (response) {
                        setState((prev: any) => ({
                            ...prev,
                            programSchema: prev?.programSchema?.filter((schema: IProgramSchema) => schema?.indicator !== specification?.indicator)
                        }))
                    }
                }).catch(err => ErrorToast(err));
        }
    }

    const submitHandler = () => {
        const formData = new FormData();
        formData.append('body', JSON.stringify({
            ...state,
            programSchema: state?.programSchema
                ?.map((item, index) => ({...item, id: item?.id || null, index: index}))
        }));
        image && formData.append("image", image);
        AdminProgramService.create(formData)
            .then((response) => {
                setState({
                    ...response?.data, programSchema: response?.data?.programSchema?.map((schema: IProgramSchema) => ({
                        ...schema,
                        indicator: schema?.id
                    }))
                });
                setImage(null);
                SuccessToast(t(`program.successfully_updated`));
            }).catch(err => ErrorToast(err));
    }

    const updateList = (newList: IProgramSchema[]) => {
        setState(prev => ({...prev, programSchema: newList}) as IProgram)
    }

    useEffect(() => {
        AdminProgramService.get()
            .then((response) => {
                const {data} = response;
                setState({
                    ...data,
                    programSchema: data?.programSchema?.map((schema: IProgramSchema) => ({
                        ...schema,
                        indicator: schema?.id
                    }))
                });
            })
            .catch((error) => ErrorToast(error));
    }, []);

    return (
        <Card>
            <Card.Body>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault()
                        submitHandler();
                    }}
                >
                    <Row className="my-3">
                        <Col md={4}>
                            <label htmlFor="name" className="required-field">
                                {t("global.title")}
                            </label>
                            <input
                                type="text"
                                value={state?.title}
                                name="title"
                                className="form-control"
                                required
                                onChange={changeHandler}
                            />
                        </Col>
                        <Col md={12} className={'mt-3'}/>
                        {state?.programSchema &&
                            <>
                                <Col md={1}/>
                                <Col md={5}>
                                    <label htmlFor="key" className="required-field">
                                        {t("global.key")}
                                    </label>
                                </Col>
                                <Col md={4}>
                                    <label htmlFor="value" className="required-field">
                                        {t("global.value")}
                                    </label>
                                </Col>
                                <Col md={2}>
                                    <label className="d-block text-start" htmlFor="bold">
                                        {t("global.bold")}
                                    </label>
                                </Col>
                            </>
                        }
                        <Col md={12}>
                            <DndProvider backend={HTML5Backend}>
                                {state?.programSchema?.map((schema, index) => {
                                    return (
                                        <DraggableSchema key={schema?.id} index={index} schema={schema}
                                                         updatePosition={updatePosition}
                                                         updateList={updateList}
                                                         list={state?.programSchema || []}
                                                         changeSpecificationHandler={changeSpecificationHandler}
                                                         handleDeleteSpecification={handleDeleteSpecification}
                                                         switchHandler={switchHandler}
                                        />
                                    )
                                })}
                            </DndProvider>
                        </Col>
                        <Col md={12} className='d-flex mt-3 justify-content-end'>
                            <button type={'button'} className="btn btn-sm btn-success"
                                    onClick={() => setState((prev: any) => ({
                                        ...prev, programSchema: prev?.programSchema?.concat({
                                            key: '',
                                            value: '',
                                            indicator: Math.random()
                                        })
                                    }))}>
                                {t("program.add_new")}
                            </button>
                        </Col>
                        <div className="mt-3">
                            <label htmlFor="imageFile"> {t("download.image")}</label>
                            <input
                                onChange={fileHandler}
                                className="form-control"
                                type="file"
                                accept="image/*"
                                id="imageFile"
                            />
                        </div>
                        {state?.image && (
                            <img
                                className="mt-3 rounded show-img-form"
                                alt="200x200"
                                src={PICTURE_URL + state?.image?.path}
                                data-holder-rendered="true"
                            />
                        )}
                    </Row>
                    <Row className="mt-3">
                        <div className="d-flex justify-content-end text-align-center">

                            <button className="btn btn-primary" type="submit">
                                {t("global.save")}
                            </button>
                        </div>
                    </Row>
                </Form>

            </Card.Body>
        </Card>
    );
};

export default AdminProgram;