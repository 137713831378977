import React, {useEffect, useState} from 'react';
import {AdminUserRegistrationService} from "./service";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ErrorToast} from "../../shared/toasters/toasters";
import List from "./components/List";

const AdminUserRegistration = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [list, setList] = useState([]);
    const [triggerUpdate, setTriggerUpdate] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [pagination, setPagination] = useState({
        page: 1,
        perPage: 10,
        term: "",
    });
    const handlePages = (updatePage: number) => {
        setPagination({...pagination, page: updatePage});
        setPage(updatePage);
    };
    const editHandler = (id: number): void => {
        navigate(`/admin/user-registration/update/${id}`);
    };

    useEffect(() => {
        AdminUserRegistrationService.getAll(pagination)
            .then((response) => {
                const {data} = response;
                setList(data?.items);
                setTotalPages(Math.ceil(data?.totalItems / data?.perPage));
            })
            .catch((error) => ErrorToast(error));
    }, [triggerUpdate, pagination]);
    return (
        <>
            <div className="col-lg-12">
                <div className="card">
                    <div className="card-body">
                        <div className="row g-2">
                            <div className="col-sm-4">
                                <div className="search-box">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="searchMemberList"
                                        placeholder="Search for name or designation..."
                                        onChange={(e: any) =>
                                            setPagination((prev: any) => ({
                                                ...prev,
                                                page: 1,
                                                term: e.target.value,
                                            }))
                                        }
                                    />
                                    <i className="ri-search-line search-icon"></i>
                                </div>
                            </div>
                            <div className="col-sm-auto ms-auto">
                                <div className="list-grid-nav hstack gap-1">
                                    <button
                                        className="btn btn-info"
                                        onClick={() => navigate("/admin/user-registration/create")}
                                    >
                                        {t("global.add")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <List
                    list={list}
                    page={page}
                    handlePages={handlePages}
                    totalPages={totalPages}
                    setTriggerUpdate={setTriggerUpdate}
                    editHandler={editHandler}
                />
            </div>
        </>
    );
};

export default AdminUserRegistration;