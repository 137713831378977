import React, {useState} from 'react';
import {Form} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ErrorToast, SuccessToast} from "../../../shared/toasters/toasters";
import {AdminCarService} from "../service";
import CreateForm from "./CreateForm";

const CreateCar = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [state, setState] = useState<any>({
        isActive: false
    });
    const [image, setImage] = useState<File>();
    const [specificationData, setSpecificationData] = useState([{
        key: '',
        value: '',
        indicator: Math.random()
    }])
    const submitHandler = () => {
        const formData = new FormData();
        formData.append("body", JSON.stringify({...state, specificationData}));
        image && formData.append("image", image);
        AdminCarService.create(formData)
            .then((response) => {
                SuccessToast(t(`cars.successfully_created`));
                navigate(-1);
                setState({});
            })
            .catch((error) => ErrorToast(error));
    };
    return (
        <div className="col-12">
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    submitHandler();
                }}
            >
                <CreateForm
                    state={state}
                    setState={setState}
                    image={image}
                    setImage={setImage}
                    specificationData={specificationData}
                    setSpecificationData={setSpecificationData}
                />
            </Form>
        </div>
    );
};

export default CreateCar;