import React, {useState} from 'react';
import {Form} from "react-bootstrap";
import CreateForm from "./CreateForm";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ErrorToast, SuccessToast} from "../../../shared/toasters/toasters";
import {AdminSectionService} from "../service";

const UpdateSection = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [state, setState] = useState<any>();
    const [image, setImage] = useState<File>();
    const submitHandler = () => {
        const formData = new FormData();
        formData.append("body", JSON.stringify({...state}));
        image && formData.append("image", image);
        AdminSectionService.update(formData, state.id)
            .then((response) => {
                SuccessToast(t(`sections.successfully_updated`));
                navigate(-1);
                setState({});
            })
            .catch((error) => ErrorToast(error));
    };

    return (
        <div className="col-12">
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    submitHandler();
                }}
            >
                <CreateForm
                    state={state}
                    setState={setState}
                    setImage={setImage}
                />
            </Form>
        </div>
    );
};

export default UpdateSection;