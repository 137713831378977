import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Card, Col, Form, Row} from "react-bootstrap";
import {ErrorToast, SuccessToast} from "../../shared/toasters/toasters";
import {InitEditorMoreFields} from "../model/components/CreateForm";
import {AdminBrandSettingsService} from "./service";

interface IBrandSettings {
    title?: string,
    subTitle?: string
    text?: string
}

const BrandSettings = () => {
    const {t} = useTranslation()
    const [state, setState] = useState<IBrandSettings>({});
    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setState((prevState) => ({...prevState, [name]: value}) as IBrandSettings)
    }
    const handleText = (text: string) => {
        setState((prevState) => ({...prevState, text}));
    };
    const submitHandler = () => {
        AdminBrandSettingsService.create(state)
            .then((response) => {
                SuccessToast(t(`brand.successfully_updated_settings`));
                setState(response.data)
            }).catch((error) => ErrorToast(error))
    }

    useEffect(() => {
        AdminBrandSettingsService.get()
            .then((response) => {
                const {data} = response;
                setState(data);
                InitEditorMoreFields(data?.text ?? "", handleText, ".text");
            })
            .catch((error) => ErrorToast(error));
    }, []);


    return (
        <Form onSubmit={(e) => {
            e.preventDefault();
            submitHandler();
        }}>
            <Card>
                <Card.Body>
                    <Row>
                        <Col md={6} className="mt-2">
                            <label>{t("global.title")}</label>
                            <input
                                name="title"
                                type="text"
                                className="form-control"
                                onChange={changeHandler}
                                value={state?.title}
                            />
                        </Col>
                        <Col md={6} className="mt-2">
                            <label>{t("global.subtitle")}</label>
                            <input
                                name="subTitle"
                                type="text"
                                className="form-control"
                                onChange={changeHandler}
                                value={state?.subTitle}
                            />
                        </Col>
                        <Col md={12} className="my-3" id="custom-editor">
                            <label htmlFor="title" className="required-field">
                                {t("global.text")}
                            </label>
                            <textarea className="editor text"/>
                        </Col>
                    </Row>
                    <div className="col-12 mt-5 d-flex justify-content-end">
                        <button className="btn btn-primary ms-3" type={'submit'}>
                            {t("global.save")}
                        </button>
                    </div>
                </Card.Body>
            </Card>
        </Form>
    );
};

export default BrandSettings;