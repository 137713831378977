import React, {useEffect} from 'react';
import {Card, Col, Row} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ErrorToast} from "../../../shared/toasters/toasters";
import {AdminSectionService} from "../service";
import {PICTURE_URL} from "../../../helpers/api.routes";
import {InitEditor} from "../../tests/components/CreateForm";
// @ts-ignore
// import Editor from "ckeditor5-custom-build/build/ckeditor";
const CreateForm = ({state, setState, setImage}: any) => {
    const {id} = useParams();
    const {t} = useTranslation();
    const navigate = useNavigate();

    const changeHandler = (event: any) => {
        const {name, value} = event.target;
        setState((prev: any) => ({...prev, [name]: value}));
    };
    const fileHandler = (e: any) => {
        setImage(e.target.files[0]);
    };
    const handleText = (text: string) => {
        setState((prev: any) => ({ ...prev, text }));
    };

    useEffect(() => {
        if (Number(id)) {
            AdminSectionService.getSingle(Number(id))
                .then((response) => {
                    const {data} = response;
                    setState(data);
                    InitEditor(data?.text, handleText);
                })
                .catch((error) => ErrorToast(error));
        } else {
            InitEditor(undefined, handleText);
        }
    }, [id]);
    return (
        <Card>
            <Card.Body>
                <Row>
                    <Col md={4}>
                        <label htmlFor="name" className="required-field">
                            {t("global.title")}
                        </label>
                        <input
                            type="text"
                            name="title"
                            className="form-control"
                            required
                            onChange={(e) => changeHandler(e as any)}
                            value={state?.id && state?.title}
                        />
                    </Col>
                    <Col md={12} className="my-3" id="custom-editor">
                        <div>
                            <label htmlFor="title" className="required-field">
                                {t("global.text")}
                            </label>
                        </div>
                        <textarea className="editor"></textarea>
                    </Col>
                    <div className="mt-3">
                        <label htmlFor="imageFile"> {t("download.image")}</label>
                        <input
                            onChange={fileHandler}
                            className="form-control"
                            type="file"
                            accept="image/*"
                            id="imageFile"
                        />
                    </div>
                    {state?.image && (
                        <img
                            className="mt-3 rounded show-img-form"
                            alt="200x200"
                            src={PICTURE_URL + state?.image.path}
                            data-holder-rendered="true"
                        />
                    )}
                </Row>
                <div className="col-12 my-2 d-flex justify-content-end text-align-center">
                    <div>
                        <button
                            className="btn btn-info"
                            type={'button'}
                            onClick={() => {
                                navigate(-1);
                                setState({} as any);
                            }}
                        >
                            {t("global.cancel")}
                        </button>
                        <button className="btn btn-primary ms-3" type="submit">
                            {state?.id ? `  ${t("global.update")}` : `${t("global.create")}`}
                        </button>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
};

export default CreateForm;