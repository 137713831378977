import {CreateForm} from "./CreateForm";
import React, {useState} from "react";
import {Form, ProgressBar} from "react-bootstrap";
import {ErrorToast, SuccessToast} from "../../../shared/toasters/toasters";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {AdminGalleryService} from "../service";
import {AxiosProgressEvent} from "axios";

export const CreatePhoto = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [triggerUpdate, setTriggerUpdate] = useState(false);
    const [state, setState] = useState<any>();
    const [images, setImages] = useState<File[]>();
    const [totalProgress, setTotalProgress] = useState(0);
    const updateGlobalProgress = (progress: number) => {
        setTotalProgress((prevProgress) => {
            const updatedProgress = prevProgress + (progress / (images || [])?.length);
            return updatedProgress > 100 ? 100 : updatedProgress;
        });
    };
    const submitHandler = async () => {
        setTotalProgress(0);
        const results = await Promise.all(Array.from(images || [])?.map((file) => processFiles(file)));
        if (results) {
            SuccessToast(t(`gallery.successfully_added_photo`));
            setTriggerUpdate(!triggerUpdate);
            setState({});
            navigate(`/admin/gallery/${state?.album}`);
        }
    };


    const processFiles = async (file: File) => {
        const formData = new FormData();
        formData.append('image[]', file);
        formData.append("body", JSON.stringify(state));
        await AdminGalleryService.create(formData, (progressEvent: AxiosProgressEvent) => {
            const progress = Math.round((progressEvent.loaded * 100) / (progressEvent?.total || 0));
            updateGlobalProgress(progress);
        }).catch(err => ErrorToast(err))
    };


    const fileHandler = (e: any) => {
        setImages(e.target.files);
    };

    return (
        <div className="col-12">
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    submitHandler();
                }}
            >
                {!!totalProgress && <ProgressBar now={totalProgress} label={`${totalProgress?.toFixed(2)}%`}  />}
                <CreateForm
                    state={state}
                    setState={setState}
                    fileHandler={fileHandler}
                    isFormDisabled={totalProgress !== 0}
                />
            </Form>
        </div>
    );
};
