import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import i18n from "../../utils/translations/i18next";

export const TopHeader = () => {
  const [currentDate, setCurrentDate] = useState("");
  const currentLang = localStorage.getItem("language");
  const translationLanguageEnum = ["en", "de"];
  const getAuth = useSelector((state: any) => state.auth);
  const formatAMPM = (date: any) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours %= 12;
    hours = hours || 12;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${hours}:${minutes} ${ampm}`;
  };
  const changeLanguageHandler = (language: string) => {
    i18n.changeLanguage(language);
    localStorage.setItem("language", language);
    window.location.reload();
  };
  const getCorrectLanguageTitle = (args: string) => {
    switch (args) {
      case "de":
        return "German";
      case "fr":
        return "French";
      case "en":
        return "English";
      case "it":
        return "Italian";
      default:
        return `NO KEY ${args}`;
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      const currentDate = new Date();
      const formattedDate = `${currentDate.getDate()}/${
        currentDate.getMonth() + 1
      }/${currentDate.getFullYear()} ${formatAMPM(currentDate)}`;
      setCurrentDate(formattedDate);
    }, 1000);
  }, []);
  return (
    <>
      <div className="top-tagbar">
        <div className="w-100">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-auto col-9">
              <div className="text-white-50 fs-13">
                <i className="bi bi-clock align-middle me-2"></i>{" "}
                <span id="current-time" className="me-2">
                  {currentDate}
                </span>
                <span>
                  {" "}
                  |{" "}
                  <span className="ms-2">
                    Version: {getAuth.projectVersion}
                  </span>
                </span>
              </div>
            </div>
            <div className="col-md-auto col-6 d-none d-lg-block">
              <div className="d-flex align-items-center justify-content-center gap-3 fs-13 text-white-50">
                <div>
                  <i className="bi bi-envelope align-middle me-2"></i>{" "}
                  <a href="mailto:info@itmedia.io" className="text-white-50">
                    info@itmedia.io
                  </a>
                </div>
                <div>
                  <a
                    href="https://itmedia.io/"
                    target="_blank"
                    className="text-decoration-none text-white-50"
                  >
                    <i className="bi bi-globe align-middle me-2"></i> itmedia.io
                  </a>
                </div>
                <div>
                  <a href="tel:+38766720720" className="text-white-50">
                    <i className="bi bi-whatsapp align-middle me-2"></i>
                    +38766720720
                  </a>
                </div>
              </div>
            </div>
            {/* <div className="col-md-auto col-3">
              <div className="dropdown topbar-head-dropdown topbar-tag-dropdown justify-content-end">
                <button
                  type="button"
                  className="btn btn-icon btn-topbar rounded-circle text-white-50 fs-13"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img
                    id="header-lang-img"
                    src={`/assets/images/flags/${currentLang}.svg`}
                    alt="Header Language"
                    height="16"
                    className="rounded-circle me-2"
                  />{" "}
                  <span id="lang-name">
                    {getCorrectLanguageTitle(currentLang as string)}
                  </span>
                </button>
                <div className="dropdown-menu dropdown-menu-end">
                  {translationLanguageEnum?.map((item: string) => (
                    <a
                      onClick={() => {
                        changeLanguageHandler(item);
                      }}
                      //  href="javascript:void(0);"
                      className="dropdown-item notify-item language py-2 pointer"
                      data-lang={item}
                      title={getCorrectLanguageTitle(item)}
                    >
                      <img
                        src={`${`/assets/images/flags/${item}.svg`}`}
                        alt="user-image"
                        className="me-2 rounded-circle"
                        height="18"
                      />
                      <span className="align-middle">
                        {getCorrectLanguageTitle(item)}
                      </span>
                    </a>
                  ))}
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
