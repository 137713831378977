import React, {useEffect, useState} from 'react';
import List from "./components/List";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {ErrorToast, SuccessToast} from "../../shared/toasters/toasters";
import {AdminSectionService} from "./service";
import _debounce from "lodash/debounce";
import DraggableBootstrapTable from "../../shared/components/draggableList/Draggable";
import {Pagination} from "../../shared/paginations/Paginations";
import {AdminBrandService} from "../brand/service";

const AdminSection = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [list, setList] = useState([]);
    const [pagination, setPagination] = useState({
        page: 1,
        perPage: 10,
        term: "",
    });
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [selectedPrompt, setSelectedPrompt] = useState<any>();

    const handlePages = (updatePage: number) => {
        setPagination({...pagination, page: updatePage});
        setPage(updatePage);
    };

    const updatePosition = (newList: any) => {
        const newListUpdated = newList?.map((item: any) => item.id);
        AdminSectionService.updatePosition({ ids: newListUpdated });
    };

    const editHandler = (id: number) => {
        navigate(`/admin/section/update/${id}`);
    };

    const deleteHandler = () => {
        selectedPrompt?.id &&
        AdminSectionService.delete(selectedPrompt?.id)
            .then(() => {
                setPagination((prev: any) => ({...prev, updatedAt: new Date()?.getTime()}))
                SuccessToast(t(`sections.successfully_deleted`));
            })
            .catch((error) => ErrorToast(error));
    };

    useEffect(() => {
        AdminSectionService.getAll(pagination)
            .then((response) => {
                const {items} = response;
                setList(items);
                setTotalPages(Math.ceil(response?.totalItems / response?.perPage));
            })
            .catch((error) => ErrorToast(error));
    }, [pagination]);

    return (
        <>
            <div className="col-12">
                <div className="card">
                    <div className="card-body d-flex align-items-center ">
                        <div className="d-flex align-items-center gap-3">
                            <div className="search-box">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="searchMemberList"
                                    onChange={_debounce((e: any) =>
                                        setPagination((prev: any) => ({
                                            ...prev,
                                            term: e.target.value,
                                        })), 300)
                                    }
                                    placeholder={t("global.search")}
                                />
                                <i className="ri-search-line search-icon"></i>
                            </div>
                        </div>
                        <button
                            className="btn btn-primary ms-auto"
                            onClick={() => navigate("/admin/section/create")}
                        >
                            {t("global.add")}
                        </button>
                    </div>
                </div>
            </div>

            {page > 1 || pagination?.term ? (
                <>
                    <div className="col-12">
                        <List
                            list={list}
                            editHandler={editHandler}
                            deleteHandler={deleteHandler}
                            selectedPrompt={selectedPrompt}
                            setSelectedPrompt={setSelectedPrompt}
                            show={show}
                            setShow={setShow}
                        />
                    </div>{" "}
                </>
            ) : (
                <div className="col-12">
                    <DraggableBootstrapTable
                        preview={true}
                        list={list!}
                        setList={setList}
                        headers={["id", "title"]}
                        title="sections"
                        onEdit={editHandler}
                        selectedPrompt={selectedPrompt}
                        setSelectedPrompt={setSelectedPrompt}
                        handleShow={handleShow}
                        show={show}
                        setShow={setShow}
                        deleteHandler={deleteHandler}
                        updatePosition={updatePosition}
                    />
                </div>
            )}
            {page && (
                <div className="d-flex justify-content-end">
                    {totalPages <= 1 ? (
                        ""
                    ) : (
                        <Pagination
                            page={page}
                            totalPages={totalPages}
                            handlePagination={handlePages}
                        />
                    )}
                </div>
            )}
        </>
    );
};

export default AdminSection;