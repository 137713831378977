import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {InitEditorMoreFields} from "../model/components/CreateForm";
import {ErrorToast, SuccessToast} from "../../shared/toasters/toasters";
import {Card, Form} from "react-bootstrap";
import {AdminMediadatenSettingsService} from "./service";

interface IMediadatenSettingsProps {
    title?: string,
    text?: string;
}

const MediadatenSettings = () => {
    const [state, setState] = useState<IMediadatenSettingsProps>({})
    const {t} = useTranslation();

    const changeHandler = (event: any) => {
        const {name, value} = event.target;
        setState((prev: any) => ({...prev, [name]: value}));
    };
    const handleText = (text: string) => {
        setState((prev: any) => ({...prev, text}));
    };
    const submitHandler = () => {
        AdminMediadatenSettingsService.create(state)
            .then((response) => {
                SuccessToast(t(`mediadaten.successfully_updated`));
                setState(response.data)
            }).catch((error) => ErrorToast(error))
    }

    useEffect(() => {
        AdminMediadatenSettingsService.get()
            .then((response) => {
                const {data} = response;
                setState(data);
                InitEditorMoreFields(data?.text ?? "", handleText, ".text");
            })
            .catch((error) => ErrorToast(error));
    }, []);
    return (
        <Form onSubmit={(e) => {
            e.preventDefault();
            submitHandler();
        }}>
            <Card>
                <Card.Body>
                    <label htmlFor="name" className="required-field">
                        {t("global.title")}
                    </label>
                    <input
                        type="text"
                        name="title"
                        className="form-control"
                        required
                        onChange={(e) => changeHandler(e)}
                        value={state?.title}
                    />
                    <label htmlFor="title" className="required-field mt-3">
                        {t("global.text")}
                    </label>
                    <textarea className="editor text"></textarea>
                    <div className="col-12 my-2 d-flex justify-content-end text-align-center">
                        <div>
                            <button
                                className="btn btn-info"
                                onClick={() => {
                                    setState({});
                                }}
                            >
                                {t("global.cancel")}
                            </button>
                            <button className="btn btn-primary ms-3" type="submit">
                                {t("global.save")}
                            </button>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </Form>
    );
};

export default MediadatenSettings;