import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {ErrorToast, SuccessToast} from "../../../shared/toasters/toasters";
import {AdminUserRegistrationService} from "../service";
import {Loader} from "../../../shared/components/Loader";
import {EmptyState} from "../../../shared/components/EmptyState";
import {Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Pagination} from "../../../shared/paginations/Paginations";
import {ConfirmDelete} from "../../../shared/components/ConfirmDelete";

const List = ({
                  list,
                  totalPages,
                  page,
                  handlePages,
                  setTriggerUpdate,
                  editHandler,
              }: any) => {
    const {t} = useTranslation();
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [selectedPrompt, setselectedPrompt] = useState<any>();
    const deleteHandler = () => {
        selectedPrompt?.id &&
        AdminUserRegistrationService.delete(selectedPrompt?.id)
            .then((response) => {
                if (response?.status === "success") {
                    SuccessToast(t(`user_registration.successfully_deleted`));
                    setTriggerUpdate((prev: boolean) => !prev);
                }
            })
            .catch((error) => ErrorToast(error));
    };
    return (
        <div className="card">
            <div className="card-header pb-0">
                <div className="d-flex justify-content-between">
                    <h4 className="card-title mg-b-0">{t("user_registration.listOfUserRegistrations")}</h4>
                </div>
            </div>
            <div className="card-body">
                {list === undefined ? (
                    <Loader/>
                ) : list.length === 0 ? (
                    <EmptyState/>
                ) : (
                    <div className="table-responsive">
                        <Table className="align-middle table-nowrap mb-0">
                            <thead className="table-light">
                            <tr>
                                <th className="col-1">{t(`global.id`)}</th>
                                <th>{t(`table_headers.company`)}</th>
                                <th>{t(`table_headers.email`)}</th>
                                <th>{t(`global.lunch`)}</th>
                                <th>{t(`table_headers.active`)}</th>
                                <th className="col-2 text-center">
                                    {t(`table_headers.actions`)}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {list?.map((prompt: any) => {
                                return (
                                    <tr key={prompt?.id}>
                                        <td>
                                            <Link to="#" className="fw-medium">
                                                #{prompt?.id}
                                            </Link>
                                        </td>
                                        <td>{prompt?.company ?? "-"}</td>
                                        <td>{prompt?.email ?? "-"}</td>
                                        <td
                                            className={`text-${
                                                prompt?.lunch ? "success" : "danger"
                                            }`}
                                        >
                                            {prompt?.lunch ? (
                                                <span>
                                                    <i className="ri-checkbox-circle-line fs-17 align-middle me-1"/>
                                                    {t('global.yes')}
                                                </span>
                                            ) : (
                                                <span>
                                                    <i className="ri-close-circle-line fs-17 align-middle me-1"/>
                                                    {t('global.no')}
                                                </span>
                                            )}
                                        </td>
                                        <td
                                            className={`text-${
                                                prompt?.active ? "success" : "danger"
                                            }`}
                                        >
                                            {prompt?.active ? (
                                                <span>
                                                    <i className="ri-checkbox-circle-line fs-17 align-middle me-1"/>
                                                    {t(`global.active`)}
                                                </span>
                                            ) : (
                                                <span>
                                                    <i className="ri-close-circle-line fs-17 align-middle me-1"/>
                                                    {t(`global.inactive`)}
                                                </span>
                                            )}
                                        </td>
                                        <td className="col-2">
                                            <div className="d-flex align-items-center justify-content-center">
                                                <button
                                                    className="btn btn-sm btn-warning d-flex align-items-center me-2"
                                                    onClick={() => editHandler(prompt?.id)}
                                                >
                                                    <i className="ri-edit-2-fill me-1"/>
                                                    {t("global.edit")}
                                                </button>
                                                <button
                                                    className="btn btn-sm btn-danger d-flex align-items-center"
                                                    onClick={() => {
                                                        handleShow();
                                                        setselectedPrompt(prompt);
                                                    }}
                                                >
                                                    <i className="ri-delete-bin-fill me-1"/>
                                                    {t("global.delete")}
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </Table>
                    </div>
                )}

                <div className="d-flex justify-content-end">
                    {totalPages <= 1 ? (
                        ""
                    ) : (
                        <Pagination
                            page={page}
                            totalPages={totalPages}
                            handlePagination={handlePages}
                        />
                    )}
                </div>
                <ConfirmDelete
                    show={show}
                    setShow={setShow}
                    itemName={selectedPrompt?.title}
                    deleteHandler={deleteHandler}
                    product={selectedPrompt}
                    selectedProduct={selectedPrompt}
                />
            </div>
        </div>
    );
};

export default List;