import React, {useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {Form} from "react-bootstrap";
import {ErrorToast, SuccessToast} from "../../../shared/toasters/toasters";
import {useTranslation} from "react-i18next";
import {CreateForm, NewsItem} from "./CreateForm";
import {AdminNewsService} from "../service";
import "../../../assets/scss/scroll.scss";

export const UpdateNews = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [disabledSubmit, setDisabledSubmit] = useState<boolean>(false);
  const [additionalFiles, setAdditionalFiles] = useState<any[]>();
  const [state, setState] = useState<NewsItem>({
    website: "",
    title: "",
    intro: "",
    content: "",
    date: "",
    hideDate: false,
    isVideoHeadLine: false,
    isComment: false,
    isPaidContent: false,
    isTransporterGuide: false,
    isPremium: false,
    isAraval: false,
    mainImage: {
      isTape: false,
      tapeText: "",
      color: "",
    },
    videoEmbed: null,
    script: "",
    isActive: true,
    redirectToUrl: "",
    subtitle: "",
    isKnowledgeNews: false,
    isEditorChoice: false,
    author: null,
    theme: null,
    isFeatured: false,
    seoTitle: "",
    seoTags: "",
    seoDescription: "",
    themeText: "",
    profilePicture: null,
    series: "",
    embeddedCode: "",
    link1: "",
    link2: "",
    link3: "",
    mailed: false,
    categories: [],
    brands: [],
    fileData: [
      {
        id: null,
        name: "",
        path: "",
        legend: "",
        credit: "",
        index: null,
      },
    ],
    additionalFiles: [],
  });

  const [files, setFiles] = useState<any[]>([]);
  const location = useLocation();

  const submitHandler = (event: any) => {
    setDisabledSubmit(true);
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("file[]", files[i]);
    }

    if (additionalFiles) {
      for (let i = 0; i < additionalFiles.length; i++) {
        formData.append("additionalFile[]", additionalFiles[i]);
      }
    }

    formData.append("body", JSON.stringify(state));

    AdminNewsService.update(formData, +id!)
      .then((response) => {
        SuccessToast(t(`news.successfully_updated`));
        navigate("/admin/news");
      })
      .catch((error) => ErrorToast(error))
      .finally(() => setDisabledSubmit(false));
  };

  return (
    <div className="col-12" id="scrollable-form">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          submitHandler(e);
        }}
      >
        {/* <div className="col-8">
          <label htmlFor="redirectToUrl" className="required-field">
            {t("global.redirectToUrl")}
          </label>
          <input
            id="redirectToUrl"
            type="url"
            name="redirectToUrl"
            className="form-control"
          />
        </div> */}
        <CreateForm
          disabledSubmit={disabledSubmit}
          submitHandler={submitHandler}
          state={state}
          setState={setState}
          files={files}
          setFiles={setFiles}
          additionalFiles={additionalFiles}
          setAdditionalFiles={setAdditionalFiles}
        />
      </Form>
    </div>
  );
};
