import React, {useEffect, useState} from "react";
import {ErrorToast} from "../../shared/toasters/toasters";
import {AdminAlbumService} from "../album/service";
import {List} from "./components/List";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import DraggableBootstrapTable from "../../shared/components/draggableList/Draggable";
import {Pagination} from "../../shared/paginations/Paginations";

export const AdminGalleryImage = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [triggerUpdate, setTriggerUpdate] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [pagination, setPagination] = useState({
        page: 1,
        perPage: 10,
        term: "",
    });
    const [list, setList] = useState(undefined);

    const isDrivingDay = localStorage.getItem("website") === "driving_day";
    const handlePages = (updatePage: number) => {
        setPagination({...pagination, page: updatePage});
        setPage(updatePage);
    };
    const editHandler = (id: number) => {
        navigate(`/admin/gallery/${id}`);
    };
    const updatePosition = (newList: any) => {
        const newListUpdated = newList?.map((item: any) => item.id);
        AdminAlbumService.updatePosition({ids: newListUpdated});
    };

    useEffect(() => {
        AdminAlbumService.getAll(pagination)
            .then((response) => {
                const {data} = response;
                setList(data?.items);
                setTotalPages(Math.ceil(data?.totalItems / data?.perPage));
            })
            .catch((error) => ErrorToast(error));
    }, [triggerUpdate, pagination]);

    return (
        <>
            <div className="col-12">
                <div className="card">
                    <div className="card-body d-flex align-items-center justify-content-between">
                        <div className="search-box me-3">
                            <input
                                type="text"
                                className="form-control"
                                id="searchMemberList"
                                onChange={(e: any) =>
                                    setPagination((prev: any) => ({
                                        ...prev,
                                        page: 1,
                                        term: e.target.value,
                                    }))
                                }
                                placeholder={t("global.search")}
                            />
                            <i className="ri-search-line search-icon"></i>
                        </div>
                        <button
                            className="btn btn-primary"
                            onClick={() => navigate("/admin/gallery/create")}
                            // onClick={() => setIsMenuOpened(true)}
                        >
                            {t("global.add")}
                        </button>
                    </div>
                </div>
            </div>
            <div className="col-12">
                {!isDrivingDay ? (
                    <List
                        page={page}
                        handlePages={handlePages}
                        totalPages={totalPages}
                        list={list}
                        setTriggerUpdate={setTriggerUpdate}
                        editHandler={editHandler}
                    />
                ) : (
                    <DraggableBootstrapTable
                        preview={true}
                        list={list!}
                        setList={setList}
                        headers={["id", "title", "date", "isActive"]}
                        title="gallery"
                        onEdit={editHandler}
                        updatePosition={updatePosition}
                        removeDeleteBtn={true}
                    />
                )}
            </div>
            {page && (
                <div className="d-flex justify-content-end ">
                    {totalPages <= 1 ? (
                        ""
                    ) : (
                        <Pagination
                            page={page}
                            totalPages={totalPages}
                            handlePagination={handlePages}
                        />
                    )}
                </div>
            )}
        </>
    );
};
