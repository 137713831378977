import React, { useEffect, useState } from "react";
import { ErrorToast, SuccessToast } from "../../shared/toasters/toasters";
import { AdminAlbumService } from "../album/service";
import { List } from "./components/List";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AdminAlternativeDrivesService } from "./service";
import { Pagination } from "../../shared/paginations/Paginations";
import DraggableBootstrapTable from "../../shared/components/draggableList/Draggable";
import Select from "react-select";
import { themeSelect } from "../../shared/components/Select";
import { SingleValue } from "react-select";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

export const AdminAlternativeDrives = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 20,
    language: null,
    term: "",
  });
  const [selectedPrompt, setSelectedPrompt] = useState<any>();
  const [list, setList] = useState(undefined);
  const { language } = useSelector((state: RootState) => state.enum);

  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };
  const editHandler = (id: number) => {
    navigate(`/admin/alternative-drives/update/${id}`);
  };
  const updatePosition = (newList: any) => {
    const newListUpdated = newList?.map((item: any) => item.id);
    AdminAlternativeDrivesService.updatePosition({ ids: newListUpdated });
  };
  useEffect(() => {
    AdminAlternativeDrivesService.getAll(pagination)
      .then((response) => {
        const { data } = response;
        setList(data?.items);
        setTotalPages(Math.ceil(data?.totalItems / data?.perPage));
        setPage(data?.page);
      })
      .catch((error) => ErrorToast(error));
  }, [triggerUpdate, pagination]);
  const handleSelectLanguage = (
    selectedSingle: SingleValue<{ label: string; value: string }>
  ) => {
    if (selectedSingle?.value as string) {
      setPagination({
        ...pagination,
        page: 1,
        perPage: 20,
        language: selectedSingle?.value as string,
      } as any);
    } else {
      setPagination({
        ...pagination,
        page: 1,
        perPage: 20,
        language: null,
      } as any);
    }
  };
  const deleteHandler = () => {
    selectedPrompt?.id &&
      AdminAlternativeDrivesService.delete(selectedPrompt?.id)
        .then((response) => {
          if (response?.status === "success") {
            SuccessToast(t(`global.successfully_deleted`));
            setTriggerUpdate((prev: boolean) => !prev);
          }
        })
        .catch((error) => ErrorToast(error));
  };
  return (
    <>
      <div className="col-12">
        <div className="card">
          <div className="card-body d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div className="search-box me-3">
                <input
                  type="text"
                  className="form-control"
                  id="searchMemberList"
                  placeholder="Search..."
                  onChange={(e: any) =>
                    setPagination((prev: any) => ({
                      ...prev,
                      page: 1,
                      term: e.target.value,
                    }))
                  }
                />
                <i className="ri-search-line search-icon"></i>
              </div>
              <div style={{ width: "200px" }} className="nesto">
                <Select
                  isClearable={true}
                  theme={themeSelect}
                  placeholder={t("global.language")}
                  defaultValue={
                    pagination?.language && {
                      label: `${t(`languages.${(pagination?.language as string)?.toLowerCase()}`)}`,
                      value: pagination?.language,
                    }
                  }
                  options={language?.map((item: string) => ({
                    label: `${t(`languages.${item?.toLowerCase()}`)}`,
                    value: item,
                  }))}
                  onChange={(e) => {
                    handleSelectLanguage(
                      e as SingleValue<{ label: string; value: string }>
                    );
                  }}
                />
              </div>
            </div>

            <button
              className="btn btn-primary"
              onClick={() => navigate("/admin/alternative-drives/create")}
            >
              {t("global.add")}
            </button>
          </div>
        </div>
      </div>
      {page > 1 || pagination?.term !== "" ? (
        <>
          <div className="col-12">
            <List
              page={page}
              handlePages={handlePages}
              totalPages={totalPages}
              list={list!}
              setTriggerUpdate={setTriggerUpdate}
              editHandler={editHandler}
              // domain={domain}
            />
          </div>{" "}
        </>
      ) : (
        <>
          <div className="col-12">
            <DraggableBootstrapTable
              isDraggable={!!(pagination as any)?.language}
              preview={true}
              list={list!}
              setList={setList}
              headers={["id", "title", "language", "color", "year", "isActive"]}
              title="alternativeDrive"
              onEdit={editHandler}
              selectedPrompt={selectedPrompt}
              setSelectedPrompt={setSelectedPrompt}
              handleShow={handleShow}
              show={show}
              setShow={setShow}
              deleteHandler={deleteHandler}
              updatePosition={updatePosition}
            />
          </div>
        </>
      )}
      {page && (
        <div className="d-flex justify-content-end ">
          {totalPages <= 1 ? (
            ""
          ) : (
            <Pagination
              page={page}
              totalPages={totalPages}
              handlePagination={handlePages}
            />
          )}
        </div>
      )}
    </>
  );
};
